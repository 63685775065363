import React, {useEffect} from 'react';
import {Background} from '../background.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import * as Utils from '../../utils.js';

/**
 *
 * @param {BlankScreenScript} props
 * @returns {JSX.Element}
 * @constructor
 */
export function PhoneConfirmScreen(props){

   let goToNextScreen = async () => {
      if(props?.userVariables?.phone && props.videoSrc){
         
         await props.setUserVariables({smsSent:true});

         BVExtras.sendSMSWithImage(
            props.userVariables.phone,
            props.smsText || '',
            props.videoSrc.src
         );


         if(props.formstackConfig?.genericConfig) {
            let isRequirementFulfilled = true;
            if(props.formstackConfig.genericConfig.requirementVariable){
               isRequirementFulfilled = props.userVariables[props.formstackConfig.genericConfig.requirementVariable];
            }
            if(isRequirementFulfilled) {
               let populatedFields = Utils.populateFormstackData(
                  props.formstackConfig.genericConfig.fields,
                  props.userVariables
               );

               BVExtras.postFormstack(
                  props.formstackConfig.apiToken,
                  props.formstackConfig.genericConfig.idForm,
                  populatedFields
               );
            }
         }

         if(props.fathomConfig?.smsClickedEventId)
            window?.fathom?.trackGoal?.(props.fathomConfig.smsClickedEventId, 1);
      }
      props.goToNextScreen();
   };

   return (
      <div className={'phone-confirm-screen screen ' + props.styles}>
         <div className={'phone-confirm-screen-content'}>
            <div className={'phone-confirm-screen-header-text'}>
               {props.headerText}
            </div>
            <div className={'phone-confirm-screen-detail-text'}>
               {props.detailText}
            </div>
            <div className={'phone-confirm-screen-value'}>
               {props.userVariables?.phone}
            </div>
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <NavigationButtons
               {...props}
               goToNextScreen={() => {
                  goToNextScreen();
               }}
            />
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}