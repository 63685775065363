import React from 'react';
import {isIOS, isMobile} from '../../systeminfo.js';
import * as SystemInfo from '../../systeminfo.js';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {resolveUrl} from '../../utils.js';

export class IntegrationReviewScreen extends React.Component {
   constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
      this.videoRef = React.createRef();

      this.onClickGoBack = () => this.props.goToPreviousScreen();
      this.state = {
         initialized: false,
      };
   }

   async componentDidMount() {
      if(this.props.videoSrc.type.includes('video')){
         try {
            await document.getElementById('review-canvas').play();
         }
         catch (e){
            console.warn(e);
         }
      }
   }

   onClickShare() {
      //let img = this.canvasRef.current.toDataURL("image/png");
      //let blob = this.dataURLtoBlob(img);

      let file = null;
      let fileName = '';

      if(this.props.videoSrc.type.includes('image')){
         file = new File([this.props.videoSrc.src], 'picture.png', {type: this.props.videoSrc.type});
         this.props?.customFunctions?.onSharePicture?.(this.props.videoSrc.src, this.props.userVariables);
      }
      else if(this.props.videoSrc.type.includes('video')){
         fileName = 'video.' + this.props.videoSrc.type.split('/')[1];
         //fileName = fileName.replace('webm', 'mp4');
         fileName = fileName.replace('quicktime', 'mov');

         file = new File(
            [this.props.videoSrc.src, this.props.videoSrc.type],
            fileName,
            {type: this.props.videoSrc.type}
         );
         this.props?.customFunctions?.onShareVideo?.(this.props.videoSrc.src, this.props.userVariables);
      }

      try {
         if(navigator.canShare && navigator.canShare({files: [file]})) {
            navigator.share({
               files: [file],
               title: this.props.shareItemTitle || 'ENTER_TITLE',
               text: this.props.shareItemText || 'ENTER_TEXT',
            })
               .then(() => {
                  console.debug('Share was successful.');
               })
               .catch((error) => {
                  if(error.name !== 'AbortError') {
                     console.warn('Sharing failed', error);
                     this.props?.customFunctions?.sharingFailed?.();
                     alert('Unable to share. Please reload the page and try again.');
                  }
               });
         } else {
            console.warn('Sharing not supported');
            this.props?.customFunctions?.sharingNotSupported?.();
         }
      }
      catch (e){
         console.warn('Sharing not supported');
         this.props?.customFunctions?.sharingNotSupported?.();
      }
   }

   render() {
      let saveText = this.props.savePhotoText;
      let retakeText = this.props.retakePhotoText;
      let retakeButtonImageUrl = this.props.retakePhotoButtonImageUrl;
      let mediaType = 'image';
      if(this.props.videoSrc.type.includes('video')){
         saveText = this.props.saveVideoText ||  this.props.savePhotoText;
         retakeText = this.props.retakeVideoText || this.props.retakePhotoText;
         retakeButtonImageUrl = this.props.retakeVideoButtonImageUrl || this.props.retakePhotoButtonImageUrl;
         mediaType = 'video';
      }

      let directDownloadScreen =
         <DefaultDirectDownloadScreen
            onClickGoBack={() => this.onClickGoBack()}
            onClickShare={() => this.onClickShare()}
            retakeText={retakeText}
            saveText={saveText}
            retakeButtonImageUrl={retakeButtonImageUrl}
            shareButtonImageUrl={this.props.shareButtonImageUrl}
            shareText={this.props.shareText}
            configEditorVariables={this.props.configEditorVariables}
            customElements={this.props.elements}
         />;

      if(isIOS()){
         directDownloadScreen =
            <IOSDirectDownloadScreen
               onClickGoBack={() => this.onClickGoBack()}
               onClickShare={() => this.onClickShare()}
               retakeText={retakeText}
               iosNextText={this.props.iosNextText}
               mediaType={mediaType}
               tapHoldBtnURL={this.props.tapHoldBtnURL}
               iosTapHoldText={this.props.iosTapHoldText}
               hideTapHoldButtonWhenPossible={this.props.hideTapHoldButtonWhenPossible}
               retakeButtonImageUrl={retakeButtonImageUrl}
               shareButtonImageUrl={this.props.shareButtonImageUrl}
               shareText={this.props.shareText}
               configEditorVariables={this.props.configEditorVariables}
               customElements={this.props.elements}
            />;
      }

      let preview = null;
      if(mediaType === 'image'){
         preview =
            <img
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
            />;
      }
      else if(mediaType === 'video'){
         preview =
            <video
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
               width={this.props.contentWidth}
               height={this.props.contentHeight}
               playsInline={true}
               loop={true}
               controls={this.props.showVideoControls ?? false}
               autoPlay={true}
            />;
      }

      //give image/video blob a small amount of time to display before hiding photobooth to reduce/remove flicker
      setTimeout(() => {
         window?.BV?.photoBoothExperience?.stop?.();
      }, 100);

      if(this.props.videoSrc) {
         return (
            <div className="review-screen screen">
               <Background configEditorVariables={this.props.configEditorVariables} elements={this.props.backgroundElements} />
               <div id={'photoboothReviewScreenLoading'}>
                  <div className={'bv-lds-ring'}>
                     <div/>
                     <div/>
                     <div/>
                     <div/>
                  </div>
               </div>
               <div className="preview-image-container">
                  {preview}
                  {
                     this.props.previewImageFrame &&
                     <img id={'photoboothReviewScreenPreviewFrame'} src={resolveUrl(this.props.previewImageFrame)} />
                  }
               </div>

               { directDownloadScreen }
               <NavigationButtons {...this.props}/>
            </div>
         );
      }

      return null;
   }
}

function DefaultDirectDownloadScreen(props){
   return (
      <div id={'defaultDirectDownloadScreen'} className={'direct-download-screen'} >
         <RetakeButton {...props} />
         {
            isMobile() &&
            <ShareButton {...props} />
         }
         <CustomElementsList
            userData={props.userData}
            customElements={props.customElements}
         />
      </div>
   );
}

function IOSDirectDownloadScreen(props){
   return (
      <div id={'iosDirectDownloadScreen'} className={'direct-download-screen'} >
         <div className={'ios-direct-download-screen-row'}>
            <RetakeButton {...props} />
            {
               SystemInfo.isIOS() && SystemInfo.getOS().version.major >= 15 &&
               <ShareButton {...props} />
            }
            <CustomElementsList
               userData={props.userData}
               customElements={props.customElements}
            />
         </div>
      </div>
   );
}

function RetakeButton(props){
   if(props.retakeButtonImageUrl){
      return (
         <button
            className={'retake-button'}
            onClick={() => props.onClickGoBack()}
         >
            <img className={'retake-button-image'} src={resolveUrl(props.retakeButtonImageUrl)} />
         </button>
      );
   }
   else if(props.retakeText) {
      return (
         <button className={'retake-button'} onClick={() => props.onClickGoBack()}>
            {props.retakeText}
         </button>
      );
   }
   return null;
}

function ShareButton(props){
   if(props.shareButtonImageUrl){
      return (
         <button
            className={'share-button'}
            onClick={() => props.onClickShare()}
         >
            <img className={'share-button-image'} src={resolveUrl(props.shareButtonImageUrl)} />
         </button>
      );
   }
   else if(props.shareText) {
      return (
         <button className={'outline-button'} onClick={() => props.onClickShare()}>
            {props.shareText}
         </button>
      );
   }
   return null;
}
