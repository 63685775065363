export class ImageProcessor{
   constructor() {
      this.isProcessing = false;
      this.pipeline = [];

      this.canvasElements = [];
      this.drawingOptions = {};
   }

   init(){

   }

   async run(image, cameraDomElement){
      if(this.isProcessing){
         return;
      }

      this.isProcessing = true;
      for(let stage of this.pipeline){
         await stage.run(image, cameraDomElement);
      }
      this.isProcessing = false;
   }

   async onWebcamStart(webcamElement){
      for(let stage of this.pipeline){
         await stage.onWebcamStart(webcamElement);
      }
   }

   async onExperienceStop(){
      for(let stage of this.pipeline){
         await stage.onExperienceStop();
      }
   }

   async onExperienceStart(){
      for(let stage of this.pipeline){
         await stage.onExperienceStart();
      }
   }

   addPipelineStep(imageProcessorStage){
      imageProcessorStage.canvasElements = this.canvasElements;
      imageProcessorStage.setDrawingOptions(this.drawingOptions);
      this.pipeline.push(imageProcessorStage);
   }

   setCanvasElements(elements){
      this.canvasElements = elements;
      for(let stage of this.pipeline){
         stage.canvasElements = elements;
      }
   }

   setDrawingOptions(options){
      this.drawingOptions = options;
      for(let stage of this.pipeline){
         stage.setDrawingOptions(options);
      }
   }
}