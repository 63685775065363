let offscreenCanvasWorker;

function getOrCreateOffscreenCanvasWorker(){
   if(!offscreenCanvasWorker){
      offscreenCanvasWorker = new Worker(resolveUrl('/offscreenCanvasWorker.js'));
      /*
      this.codecWorker.onmessage = (e) => {
         if(e.data.type === 'encodeComplete'){
            let videoSrc = {src: e.data.webmBlob, type: 'video/webm'};
            this.finalizeVideoSrc(e.data.webmBlob, videoSrc);
         }
      };

       */

   }
   return offscreenCanvasWorker;
}



async function drawImage(renderContext, image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight){
   //let worker = getOrCreateOffscreenCanvasWorker();
   //worker.postMessage({ renderContext, image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight});
   //let bmp = await createImageBitmap(image);
   //renderContext.drawImage(bmp, 0, 0);

   renderContext.drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight);
}

/**
 *
 * @param {object} source
 * @param {number} canvasWidth
 * @param {number} canvasHeight
 * @param {CanvasRenderingContext2D}renderContext
 */
export async function renderSourceToCanvas(source, canvasWidth, canvasHeight, renderContext){
   if(!source.captureInRecording || (!source.src && !source.configEditorVariableName && source.type !== 'webcam')){
      return;
   }
   console.debug('drawing ' + (source.selector || source.idElement || source.classList) );
   let startTime = performance.now();

   let element = source.imageProcessorCanvas || source.element;

   let elementStyles = {width: '', height: '', top: '', left:''};
   try {
      elementStyles = getComputedStyle(element);
   }
   catch (e){
      console.debug(e);
   }
   let parentStyles = {};

   try {
      parentStyles = getComputedStyle(element.parent || element.parentElement);
   }
   catch (e){
      console.debug(e);
   }

   let sWidth = element.videoWidth || element.naturalWidth || element.width;
   let sHeight = element.videoHeight || element.naturalHeight || element.height;
   let sAspect = sWidth / sHeight;
   let cAspect = canvasWidth / canvasHeight;

   let drawWidth = canvasWidth;
   let drawHeight = canvasHeight;

   if(source.flipXFinal){
      renderContext.save();
      renderContext.translate(canvasWidth,0);
      renderContext.scale(-1,1);
   }

   let cOffset = 0;

   let destX = 0;
   let destY = 0;

   if(elementStyles['width'].includes('%')){
      let scale = parseInt(elementStyles['width']);
      drawWidth = drawWidth * scale * 0.01;
   }
   else if(elementStyles['width'].includes('px') && parentStyles.width){
      let w = parseInt(elementStyles['width']);
      let parentW = parseInt(parentStyles['width']);
      if(w > 0 && parentW > 0){
         let scale = w/parentW;
         scale = Math.min(scale, 1);
         drawWidth = drawWidth * scale;
      }
   }

   if(elementStyles['height'].includes('%')){
      let scale = parseInt(elementStyles['height']);
      drawHeight = drawHeight * scale * 0.01;
   }
   else if(elementStyles['height'].includes('px') && parentStyles.height){
      let h = parseInt(elementStyles['height']);
      let parentH = parseInt(parentStyles['height']);
      if(h > 0 && parentH > 0){
         let scale = h/parentH;
         scale = Math.min(scale, 1);
         drawHeight = drawHeight * scale;
      }
   }

   if(elementStyles['left'].includes('%')){
      let scale = parseInt(elementStyles['left']);
      destX = canvasWidth * scale * 0.01;
   }
   else if(elementStyles['left'].includes('px') && parentStyles.width){
      let l = parseInt(elementStyles['left']);
      let parentW = parseInt(parentStyles['width']);
      if(l !== 0 && parentW > 0){
         let scale = l/parentW;
         scale = Math.min(scale, 1);
         destX = canvasWidth * scale;
      }
   }

   if(elementStyles['top'].includes('%')){
      let scale = parseInt(elementStyles['top']);
      destY = canvasHeight * scale * 0.01;
   }
   else if(elementStyles['top'].includes('px') && parentStyles.height){
      let t = parseInt(elementStyles['top']);
      let parentH = parseInt(parentStyles['height']);
      if(t !== 0 && parentH > 0){
         let scale = t/parentH;
         scale = Math.min(scale, 1);
         destY = canvasHeight * scale;
      }
   }

   //if(sHeight !== drawHeight){
   //   destY =
   //}

   if(sWidth !== drawWidth && source.flipXFinal){
      destX = canvasWidth - drawWidth;
   }

   if(sAspect === cAspect) {
      await drawImage(renderContext, element, 0, 0, sWidth, sHeight, destX, destY, drawWidth, drawHeight);
   }
   else if(elementStyles.objectFit === 'cover'){
      let sx = 0;
      let sy = 0;
      if( sAspect < cAspect){
         //going to scale source until widths are equal
         let scaleFactor = sWidth / drawWidth;
         let adjustedHeight = drawHeight * scaleFactor;
         let heightDiff = Math.abs(adjustedHeight - sHeight);

         let objectPositionYPercentage = elementStyles.objectPosition.split(' ')[1].replaceAll('%', '');

         sy = Math.round(heightDiff) * parseInt(objectPositionYPercentage) * 0.01;

         //clamp the max since iOS will not draw anything if you specify a frame too large;
         if((sy + adjustedHeight) > sHeight){
            adjustedHeight = sHeight - sy;
         }

         renderContext.drawImage(
            element,
            sx,
            sy,
            sWidth,
            adjustedHeight,
            destX,
            destY,
            drawWidth,
            drawHeight
         );
      }
      else if( sAspect > cAspect){
         //going to scale source until heights are equal
         let scaleFactor = sHeight / drawHeight;
         let adjustedWidth = drawWidth * scaleFactor;
         let widthDiff = Math.abs(adjustedWidth - sWidth);

         let objectPositionXPercentage = elementStyles.objectPosition.split(' ')[0].replaceAll('%', '');

         sx = Math.round(widthDiff) * parseInt(objectPositionXPercentage) * 0.01;

         //clamp the max since iOS will not draw anything if you specify a frame too large;
         if((sx + adjustedWidth) > sWidth){
            adjustedWidth = sWidth - sx;
         }

         renderContext.drawImage(
            element,
            sx,
            sy,
            adjustedWidth,
            sHeight,
            destX,
            destY,
            drawWidth,
            drawHeight
         );
      }
   }
   else if(sAspect < 1) {
      //smallest side is width, clip along height
      let scaleFactor = drawWidth / sWidth;
      let adjustedHeight = sHeight * scaleFactor;
      let heightDiff = adjustedHeight - drawHeight;
      renderContext.drawImage(element,
         0, //source start x
         0, //source start y
         sWidth, //source x sample size
         sHeight, //source y sample size
         0, //destination start x
         cOffset - (heightDiff / 2), //destination start y
         drawWidth, //destination width draw size
         drawHeight + heightDiff //destination height draw size
      );
   } else {
      //smallest side is height, clip along width
      let scaleFactor = drawHeight / sHeight;
      let adjustedWidth = sWidth * scaleFactor;
      let widthDiff = adjustedWidth - drawWidth;
      renderContext.drawImage(element,
         0, //source start x
         0, //source start y
         sWidth, //source x sample size
         sHeight, //source y sample size
         -(widthDiff / 2), //destination start x
         cOffset, //destination start y
         drawWidth + widthDiff, //destination width draw size
         drawHeight //destination height draw size
      );
   }

   if(source.flipXFinal){
      renderContext.restore();
   }

   console.debug(`Time Taken: ${performance.now() - startTime}`);
}

export function dataURLtoBlob(dataURL) {
   let array, binary, i;
   try {
      binary = atob(dataURL.split(',')[1]);

      array = [];
      i = 0;
      while(i < binary.length) {
         array.push(binary.charCodeAt(i));
         i++;
      }
   }
   catch (e){
      console.warn(e);
      console.log(dataURL);
      return null;
   }

   return new Blob([new Uint8Array(array)], {
      type: 'image/png'
   });
}

export /**
 *
 * @param {string} url
 * @returns {Promise<string>}
 */
function loadSvg(url){
   return new Promise(resolve => {
      let ajax = new XMLHttpRequest();
      ajax.open('GET', url, true);
      ajax.send();

      ajax.onload = function () {
         resolve(ajax.responseText);
      };
   });
}

export function resolveUrl(url){
   if(!url){
      return '';
   }

   if(!url.includes){
      return url;
   }

   if(url.includes('://')){
      return url;
   }
   //escape route if needed (especially for dev)
   else if(url[0] ==='^'){
      return (url.substring(1));
   }
   else {
      return (window?.BV?.photoboothScript?.baseUrl || '') + url;
   }
}

export function populateFormstackData(fieldDescription, userVariables) {
   let ret = [];
   for(let field of fieldDescription) {
      if(field.valueDescriptor === 'boothName') {
         ret.push({id: field.idField, data: window.BV?.settings?.boothName});
      } else {
         ret.push({id: field.idField, data: userVariables[field.valueDescriptor]});
      }
   }
   return ret;
}

export function toHHMMSS  (secondValue) {
   let hours   = Math.floor(secondValue / 3600);
   let minutes = Math.floor((secondValue - (hours * 3600)) / 60);
   let seconds = secondValue - (hours * 3600) - (minutes * 60);
   if(hours   < 10) {hours   = '0' + hours;}
   if(minutes < 10) {minutes = '0' + minutes;}
   if(seconds < 10) {seconds = '0' + seconds;}
   return hours + ':' + minutes + ':' + seconds;
}

export function percentageOf  ( value , total) {
   return (value / total * 100).toFixed(2);
}

export function getTodayDate () {
   let today = new Date();
   let dd = String(today.getDate()).padStart(2, '0');
   let mm = String(today.getMonth() + 1).padStart(2, '0');
   let yyyy = today.getFullYear();
   today = `${yyyy}-${mm}-${dd}`;
   return today;
}

/**
 *
 * @param {number} min 
 * @param {number} max
 * @returns {number}
 */

export function getRandonInt(min, max) {
   min = Math.ceil(min);
   max = Math.floor(max);
   return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function getFileExtension(value){
   return value.split('.').pop();
}