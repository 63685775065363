import {IImageProcessorStage} from './IImageProcessorStage.js';
import * as bodySegmentation from '@tensorflow-models/body-segmentation';

export class SelfieSegmentationStage extends  IImageProcessorStage{
   constructor() {
      super();
   }

   async init(){
      if(!window.BV){
         window.BV = {};
      }
      if(window.BV.selfieSegmentation){
         console.warn('SelfieSegmentation processing stage can only be used once');
         return;
      }

      const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation;
      const segmenterConfig = {
         runtime: 'mediapipe',
         solutionPath: '/bin/selfie_segmentation'
      };

      this.selfieSegmentation = window.BV.selfieSegmentation = await bodySegmentation.createSegmenter(model, segmenterConfig);
   }

   onDrawingOptionsChanged() {
      this.selfieSegmentationCanvasElement = document.createElement('canvas');
      this.selfieSegmentationCanvasElement.width = this.drawingOptions.dWidth;
      this.selfieSegmentationCanvasElement.height = this.drawingOptions.dHeight;
      this.selfieSegmentationCanvasElement.setAttribute('id', 'selfieSegmentationCanvas');
      document.body.append(this.selfieSegmentationCanvasElement);

      this.canvasCtx = this.selfieSegmentationCanvasElement.getContext('2d');
      super.onDrawingOptionsChanged();
   }

   onResults(results, originalImage){

      if(results.length === 0){
         this.segmentationInProcess = false;
         return;
      }
      const foregroundColor = {r: 0, g: 0, b: 0, a: 255};
      const backgroundColor = {r: 0, g: 0, b: 0, a: 0};
      const drawContour = false;
      const foregroundThreshold = 0.6;
      bodySegmentation.toBinaryMask(
         results, foregroundColor, backgroundColor, drawContour, foregroundThreshold
      ).then(async binaryMask => {
         let {dx, dy, dWidth, dHeight, imageProcessorMaskBlurAmount} = this.drawingOptions;
         for(let canvas of this.canvasElements) {
            let ctx = canvas.getContext('2d');
            let canvasCtx = ctx;

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, dWidth, dHeight);

            let imageBitmap = await createImageBitmap(binaryMask);

            ctx.filter = `blur(${imageProcessorMaskBlurAmount}px)`;

            ctx.drawImage(
               imageBitmap,
               dx, dy, dWidth, dHeight,
               dx, dy, dWidth, dHeight
            );

            if(canvas.isForeground) {
               canvasCtx.globalCompositeOperation = 'source-in';
            }
            else {
               canvasCtx.globalCompositeOperation = 'source-out';
            }

            ctx.filter = 'none';

            canvasCtx.drawImage(
               originalImage,
               dx, dy, dWidth, dHeight,
               dx, dy, dWidth, dHeight
            );

            canvasCtx.restore();

         }
         this.segmentationInProcess = false;
      });
   }

   async run(image){
      if(this.segmentationInProcess){
         return;
      }

      let {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.drawingOptions;

      if(dWidth) {
         this.canvasCtx.drawImage(
            image,
            dx, dy, dWidth, dHeight,
            dx, dy, dWidth, dHeight
         );

         this.segmentationInProcess = true;
         const segmentationConfig = {multiSegmentation: true, segmentBodyParts: false};
         const people = await this.selfieSegmentation.segmentPeople(this.selfieSegmentationCanvasElement, segmentationConfig);
         this.onResults(people, this.selfieSegmentationCanvasElement);
      }
   }


}