export const ScreenType = {
   AgeGate: 0,
   Welcome: 1,
   //Selection: 2,
   Capture: 3,
   Review: 4,
   //Finished: 5,
   Error: 6,
   CameraPermission: 7,
   UserSelectionLegacy: 8,
   TextFormLegacy: 9,
   Blank: 10,
   BlankImage: 11,
   BlankWebcam: 12,
   PdfView: 13,
   PhoneConfirmScreen: 14,
   EmailConfirmScreen: 15,
   IntegrationReviewScreen: 16,
   CalibrationPage: 17,
   QRConfirmScreen: 18,
   ContactInfoIntegratedFormScreen: 19,
   CaptureVideo: 20,
   GetMediaScreen: 21,
   CustomizationScreen: 22,
   ManualCaptureScreen: 23,
   UserSelection: 24,
   TextForm: 25,
   ImageReview: 26,
};

export let ScreenTypeLookup = {};
for(let key of Object.keys(ScreenType)){
   ScreenTypeLookup[ScreenType[key]] = key;
}

export const TextFormFieldType = {
   Email: 'email',
   Number: 'number',
   Phone: 'phone',
   Text: 'text',
   ZipCode: 'zipCode',
   TextNoDomain: 'textNoDomain'
};

export const idElementOptions = [
   'video1', 'video2', 'video3', 'video4', 'video5',
   'cardBorder', 'cardBorder1', 'cardBorder2', 'cardBorder3', 'cardBorder4', 'cardBorder5', 'lensId'
];
export const textFormVariableNames = [
   'optIn', 'acceptPhone', 'acceptEmail'
];
export class DomElement{
   /**
    * @param props
    * @param {string} props.id
    * @param {string} props.class
    * @param {innerText} props.innerText
    */
   constructor(props) {
      this.id = props.id;
      this.class = props.class;
      this.innerText = props.innerText;
   }
}

export class DomElementButton extends DomElement{
   /**
    * @param props
    * @param {string} props.id
    * @param {string} props.class
    * @param {innerText} props.innerText
    * @param {function} props.onClick
    */
   constructor(props) {
      super(props);
      this.onClick = props.onClick;
   }
}