import {loadSvg, resolveUrl} from '../utils.js';
import * as SystemInfo from '../systeminfo.js';
import {ImageProcessor} from "./imageProcessor.js";
import {ColorLevelSet} from "./imageProcessorStages/colorLevelSet.js";

let videoType = SystemInfo.getVideoTypeForBrowser();

function setCommonAttributes(element, source, zIndex){
   element.setAttribute('id', source.idElement);
   if(source.classList){
      for(let cssClass of source.classList.split(' ')){
         element.classList.add(cssClass);
      }
   }
   element.style.zIndex = zIndex;
   element.style.order = zIndex;

   if(source.onlyVisibleInRecording){
      element.style.zIndex = '-1';
      element.style.display = 'none';
   }
}

function getWidth(element){
   return element.videoWidth || element.naturalWidth || element.width;
}

function getHeight(element){
   return element.videoHeight || element.naturalHeight || element.height;
}

export async function createVideoForSource(source, parentElement, zIndex){
   if(!source.src){
      console.warn('source undefined for ' + source.idElement);
      return;
   }

   let extension = source.src.split('.').pop();
   if(extension === 'png'){
      return await createImageForSource(source, parentElement, zIndex);
   }
   if(extension !== videoType){
      //this means there is no extension, so assume it was left off
      if(extension.length > 4){
         source.src += '.' + videoType;
      }
      else {
         source.src = source.src.split('.').slice(0, -1).concat(videoType).join('.');
      }
   }

   let vid = document.createElement('video');

   if(source.flipXPreview)
      vid.style.transform = 'scaleX(-1)';

   let src = source.src;
   if(window.BV?.photoBoothExperience?.overlayVariables && source.configEditorVariableName) {
      let variable = window.BV.photoBoothExperience.overlayVariables.find(
         x=>x.configEditorVariableName === source.configEditorVariableName
      );
      src = variable.src;
   }

   vid.classList.add('bv-photobooth-video');
   vid.setAttribute('playsInline', '');
   vid.setAttribute('muted', 'true');
   vid.setAttribute('crossOrigin', 'anonymous');
   if(source.loop)
      vid.setAttribute('loop', source.loop);

   vid.setAttribute('src', resolveUrl(src));
   vid.setAttribute('preload', 'auto');

   source.element = vid;

   if(source.filters.length === 0) {
      setCommonAttributes(vid, source, zIndex);
      parentElement.append(vid);
      return vid;
   }
   else {
      //TODO: generalize this to multiple filters and different types
      if(source.filters[0].name === 'colorBalance') {
         return await new Promise(resolve => {
            let processor = new ImageProcessor();
            let colorLevelSet = new ColorLevelSet();
            processor.addPipelineStep(colorLevelSet);

            source.imageProcessor = processor;
            vid.onloadedmetadata = () => {
               colorLevelSet.init(source);

               let drawingOptions = {
                  sx: 0,
                  sy: 0,
                  sWidth: getWidth(vid),
                  sHeight: getHeight(vid),
                  dx: 0,
                  dy: 0,
                  dWidth: getWidth(vid),
                  dHeight: getHeight(vid),
               };
               processor.setDrawingOptions(drawingOptions);

               let canvas = document.createElement('canvas');
               setCommonAttributes(canvas, source, zIndex);
               canvas.setAttribute('height', getHeight(vid).toString());
               canvas.setAttribute('width', getWidth(vid).toString());

               parentElement.append(vid);
               parentElement.append(canvas);
               processor.setCanvasElements([canvas]);
               processor.run(vid);
               source.imageProcessorCanvas = canvas;
               resolve(vid);
            };

         });


      }
   }
}

export async function createWebcamForSource(source, parentElement, width, height, zIndex){
   let container = document.createElement('div');
   container.classList.add('webcam-container');
   let webcam = document.createElement('canvas');
   webcam.setAttribute('id', source.idElement);
   webcam.setAttribute('height', height);
   webcam.setAttribute('width', width);
   webcam.classList.add('webcam-video');
   if(source.classList) {
      for(let cssClass of source.classList.split(' ')) {
         webcam.classList.add(cssClass);
      }
   }
   webcam.setAttribute('muted', '');
   webcam.setAttribute('playsInline', '');
   webcam.style.zIndex = zIndex;
   webcam.style.order = zIndex;
   if(source.flipXPreview)
      webcam.style.transform = 'scaleX(-1)';

   container.append(webcam);
   parentElement.append(webcam);
   webcam.videoGroup = parentElement.videoGroup;
   webcam.isForeground = source.isForeground;

   return webcam;
}

async function createCanvasForImageBlobSource(source, parentElement, zIndex){

   if(source.src instanceof Blob ){
      //source.src = window.URL.createObjectURL(source.src);
      //useCrossOriginAnonymous = false;
   }
   let width = window.BV.photoBoothExperience.width;
   let height = window.BV.photoBoothExperience.height;


   let canvas = document.createElement('canvas');
   setCommonAttributes(canvas, source, zIndex);
   canvas.setAttribute('height', height.toString());
   canvas.setAttribute('width', width.toString());

   let ctx = canvas.getContext('2d');
   let img = new window.Image();

   return await new Promise(resolve => {
      img.addEventListener('load', () => {
         ctx.drawImage(img,0,0, width, height);
         parentElement.append(canvas);
         source.element = canvas;
         resolve(canvas);
      });
      img.setAttribute('src', window.URL.createObjectURL(source.src));
   });




}

export async function createImageForSource(source, parentElement, zIndex){
   if(!source.src && !source.configEditorVariableName){
      console.warn('source undefined for ' + source.idElement);
      return;
   }

   if(source.src instanceof Blob ){
      return await createCanvasForImageBlobSource(source, parentElement, zIndex);
   }

   let img = document.createElement('img');

   if(source.classList) {
      for(let cssClass of source.classList.split(' ')) {
         img.classList.add(cssClass);
      }
   }

   let configEditorVariable;

   if(
      source.configEditorVariableName
      && window.BV.userData
      && window.BV.userData.hasOwnProperty(source.configEditorVariableName)
   )
   {
      configEditorVariable =  window.BV.userData[source.configEditorVariableName].find(
         x => x.selectedBy.includes(window.BV.photoboothScript.idScript)
      );
   }

   // let cacheBustString = '?' + new Date().getTime();

   let src = configEditorVariable ? configEditorVariable.src : source.src;

   source.element = img;

   if(source.flipXPreview)
      img.style.transform = 'scaleX(-1)';

   if(source.filters.length === 0) {
      setCommonAttributes(img, source, zIndex);

      img.setAttribute('crossOrigin', 'Anonymous');

      img.setAttribute('src', resolveUrl(src));
      parentElement.append(img);
      return img;
   }
   else {
      //TODO: generalize this to multiple filters and different types
      if(source.filters[0].name === 'colorBalance') {
         return await new Promise(resolve => {
            let processor = new ImageProcessor();
            let colorLevelSet = new ColorLevelSet();
            processor.addPipelineStep(colorLevelSet);

            source.imageProcessor = processor;
            img.onload = () => {
               colorLevelSet.init(source);

               let drawingOptions = {
                  sx: 0,
                  sy: 0,
                  sWidth: getWidth(img),
                  sHeight: getHeight(img),
                  dx: 0,
                  dy: 0,
                  dWidth: getWidth(img),
                  dHeight: getHeight(img)
               };
               processor.setDrawingOptions(drawingOptions);

               let canvas = document.createElement('canvas');
               setCommonAttributes(canvas, source, zIndex);
               canvas.setAttribute('height', getHeight(img).toString());
               canvas.setAttribute('width', getWidth(img).toString());
               canvas.classList.add('image-processor-canvas');

               parentElement.append(img);
               parentElement.append(canvas);
               processor.setCanvasElements([canvas]);
               processor.run(img);
               source.imageProcessorCanvas = canvas;
               resolve();
            };

            img.setAttribute('crossOrigin', 'Anonymous');
            img.setAttribute('src', resolveUrl(src));
         });


      }
   }
}

export async function createSvgForSource(source, parentElement, zIndex){
   if(!source.src){
      console.warn('source undefined for ' + source.idElement);
      return;
   }
   let container = document.createElement('div');


   let src = source.src;
   if(window.BV?.photoBoothExperience?.overlayVariables && source.configEditorVariableName) {
      let variable = window.BV.photoBoothExperience.overlayVariables.find(x=>x.configEditorVariableName === source.configEditorVariableName);
      if(variable)
         src = variable.src;
   }

   container.innerHTML = await loadSvg(resolveUrl(src));
   let svg = Array.from(container.childNodes).find(x => x.nodeName === 'svg');
   container.replaceWith(svg);


   svg.setAttribute('id', source.idElement);
   if(source.classList){
      for(let cssClass of source.classList.split(' ')){
         svg.classList.add(cssClass);
      }
   }

   svg.style.zIndex = zIndex;
   //svg.setAttribute('preserveAspectRatio', 'none');

   parentElement.append(svg);
   return svg;
}

export async function createCustomTextForSource(source, parentElement, width, height, zIndex){
   let element = document.createElement('canvas');
   element.setAttribute('height', height);
   element.setAttribute('width', width);
   const ctx = element.getContext('2d');

   let adjustedValues = {
      customTextX: source.customTextX,
      customTextY: source.customTextY,
      customTextWidth: source.customTextWidth,
      customTextHeight: source.customTextHeight
   };

   if(source?.customTextX?.includes?.('%')){
      adjustedValues.customTextX = width * parseInt(source.customTextX) * 0.01;
   }

   if(source?.customTextY?.includes?.('%')){
      adjustedValues.customTextY = height * parseInt(source.customTextY) * 0.01;
   }

   if(source?.customTextWidth?.includes?.('%')){
      adjustedValues.customTextWidth = width * parseInt(source.customTextWidth) * 0.01;
   }

   if(source?.customTextHeight?.includes?.('%')){
      adjustedValues.customTextHeight = height * parseInt(source.customTextHeight) * 0.01;
   }


   if(source.customTextFont) {
      ctx.font = source.customTextFont;
   }

   if(source.customTextAlign){
      ctx.textAlign = source.customTextAlign;
   }

   let textMetrics = ctx.measureText(source.src);
   while(textMetrics.width > adjustedValues.customTextWidth || textMetrics.height > adjustedValues.customTextHeight){
      let match = ctx.font.match(/^\d*/);
      if(!match[0]){
         break;
      }
      let newFontSize = parseInt(match[0]) - 1;
      ctx.font = ctx.font.replace(match[0], newFontSize.toString());
      textMetrics = ctx.measureText(source.src);

   }

   if(source.customTextTransform){
      let transform = source.customTextTransform.split(',').map((x) => parseFloat(x));

      ctx.setTransform(transform[0], transform[1], transform[2], transform[3], transform[4] ,transform[5]);
   }

   ctx.fillStyle = source.customTextFillColor || 'rgb(0, 0, 0)';
   if(source.customTextStrokeColor){
      ctx.strokeStyle = source.customTextStrokeColor;
      ctx.strokeText(source.src, adjustedValues.customTextX || 0, adjustedValues.customTextY || 0);
   }
   else {
      ctx.fillText(source.src, adjustedValues.customTextX || 0, adjustedValues.customTextY || 0);
   }

   if(source.customTextTransform){
      ctx.setTransform(1, 0, 0, 1, 0, 0);
   }

   element.setAttribute('id', source.idElement);
   if(source.classList){
      for(let cssClass of source.classList.split(' ')){
         element.classList.add(cssClass);
      }
   }

   element.zIndex = zIndex;

   setCommonAttributes(element, source, zIndex);

   parentElement.append(element);
   return element;
}