import React from 'react';
import {isIOS, getOS, isMobile} from '../../systeminfo.js';
import * as SystemInfo from '../../systeminfo.js';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {resolveUrl} from '../../utils.js';

export class ReviewScreen extends React.Component {
   constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
      this.videoRef = React.createRef();

      this.onClickGoBack = () => this.props.goToPreviousScreen();
      this.state = {
         initialized: false,
      };
   }

   async componentDidMount() {
      if(this.props.videoSrc.type.includes('video')){
         try {
            await document.getElementById('review-canvas').play();
         }
         catch (e){
            console.warn(e);
         }
      }

      this.doNotSendSessionData = localStorage.getItem('doNotSendSessionData');
      if(this.doNotSendSessionData === "false")
         this.doNotSendSessionData = false;

      if(window.BVExtras?.isWeb && this.props.webSubmitSessionOnLoad && !this.doNotSendSessionData) {
         let userVariables = this?.props?.userVariables || {};
         userVariables.smsSent = true;
         window.BVExtras?.submitSessionData?.({
            userVariables,
            startTime: this?.props?.sessionStartTime,
            endTime: Date.now()
         });
      }
   }

   onClickEmail() {
      if(this.props.videoSrc.type.includes('image')){
         this.props?.customFunctions?.onClickEmail?.(
            this.props.videoSrc.src,
            this.props.userVariables,
            this.props.videoSrc.type
         );
      }
      else if(this.props.videoSrc.type.includes('video')){
         this.props?.customFunctions?.onClickEmail?.(
            this.props.videoSrc.src,
            this.props.userVariables,
            this.props.videoSrc.type
         );
      }
      if(this.props.emailButtonNextIndex){
         this.props.goToScreen(this.props.emailButtonNextIndex);
      }
   }

   onClickSMS() {
      if(this.props.videoSrc.type.includes('image')){
         this.props?.customFunctions?.onClickSMS?.(
            this.props.videoSrc.src,
            this.props.userVariables,
            this.props.videoSrc.type
         );
      }
      else if(this.props.videoSrc.type.includes('video')){
         this.props?.customFunctions?.onClickSMS?.(
            this.props.videoSrc.src,
            this.props.userVariables,
            this.props.videoSrc.type
         );
      }
      if(this.props.smsButtonNextIndex){
         this.props.goToScreen(this.props.smsButtonNextIndex);
      }
   }

   onClickShare() {
      //let img = this.canvasRef.current.toDataURL("image/png");
      //let blob = this.dataURLtoBlob(img);
      
      let file = null;
      let fileName = '';

      if(this.props.videoSrc.type.includes('image')){
         file = new File([this.props.videoSrc.src], 'picture.png', {type: this.props.videoSrc.type});
         this.props?.customFunctions?.onSharePicture?.(this.props.videoSrc.src, this.props.userVariables);
      }
      else if(this.props.videoSrc.type.includes('video')){
         fileName = 'video.' + this.props.videoSrc.type.split('/')[1];
         //fileName = fileName.replace('webm', 'mp4');
         fileName = fileName.replace('quicktime', 'mov');

         file = new File(
            [this.props.videoSrc.src, this.props.videoSrc.type],
            fileName,
            {type: this.props.videoSrc.type}
         );
         this.props?.customFunctions?.onShareVideo?.(this.props.videoSrc.src, this.props.userVariables);
      }

      try {
         if(navigator.canShare && navigator.canShare({files: [file]})) {
            navigator.share({
               files: [file],
               title: this.props.shareItemTitle,
               text: this.props.shareItemText,
            })
               .then(() => {
                  console.debug('Share was successful.');
                  if(window.BVExtras?.isWeb && !this.props.webSubmitSessionOnLoad && !this.doNotSendSessionData) {
                     let userVariables = this?.props?.userVariables || {};
                     userVariables.smsSent = true;
                     window?.BVExtras?.submitSessionData?.({
                        userVariables,
                        startTime: this?.props?.sessionStartTime,
                        endTime: Date.now()
                     });
                  }
               })
               .catch((error) => {
                  if(error.name !== 'AbortError') {
                     console.warn('Sharing failed', error);
                     this.props?.customFunctions?.sharingFailed?.();
                     alert('Unable to share. Please reload the page and try again.');
                  }
               });
         } else {
            console.warn('Sharing not supported');
            this.props?.customFunctions?.sharingNotSupported?.();
         }
      }
      catch (e){
         console.warn('Sharing not supported');
         this.props?.customFunctions?.sharingNotSupported?.();
      }
   }

   onClickSave() {
      let dlLink = document.createElement('a');

      if(this.props.videoSrc.type.includes('image')){
         dlLink.href = window.URL.createObjectURL(this.props.videoSrc.src);
         dlLink.download = 'picture.png';
      }
      else if(this.props.videoSrc.type.includes('video')){
         let fileName = 'video.' + this.props.videoSrc.type.split('/')[1];
         //fileName = fileName.replace('webm', 'mp4');
         fileName = fileName.replace('quicktime', 'mov');
         dlLink.href = window.URL.createObjectURL(this.props.videoSrc.src);
         dlLink.download = fileName;
      }

      document.body.append(dlLink);
      dlLink.click();
      this.props.goToNextScreen();
   }

   onClickSaveIOS() {
      this.props.goToNextScreen();
   }

   render() {
      let saveText = this.props.savePhotoText || this.props.saveText;
      let saveButtonImageUrl = this.props.savePhotoButtonImageUrl || this.props.saveButtonImageUrl;
      let retakeText = this.props.retakePhotoText;
      let retakeButtonImageUrl = this.props.retakePhotoButtonImageUrl;
      let mediaType = 'image';
      if(this.props.videoSrc.type.includes('video')){
         saveText = this.props.saveVideoText ||  this.props.savePhotoText;
         saveButtonImageUrl = this.props.saveVideoButtonImageUrl || saveButtonImageUrl;
         retakeText = this.props.retakeVideoText || this.props.retakePhotoText;
         retakeButtonImageUrl = this.props.retakeVideoButtonImageUrl || this.props.retakePhotoButtonImageUrl;
         mediaType = 'video';
      }

      let directDownloadScreen =
         <DefaultDirectDownloadScreen
            onClickGoBack={() => this.onClickGoBack()}
            onClickSave={() => this.onClickSave()}
            onClickShare={() => this.onClickShare()}
            retakeText={retakeText}
            saveText={saveText}
            saveButtonImageUrl={saveButtonImageUrl}
            retakeButtonImageUrl={retakeButtonImageUrl}
            shareButtonImageUrl={this.props.shareButtonImageUrl}
            shareText={this.props.shareText}
            videoSrc={this.props.videoSrc}
         />;

      if(isIOS()){
         directDownloadScreen =
            <IOSDirectDownloadScreen
               onClickGoBack={() => this.onClickGoBack()}
               onClickSave={() => this.onClickSaveIOS()}
               onClickShare={() => this.onClickShare()}
               retakeText={retakeText}
               iosNextText={this.props.iosNextText}
               mediaType={mediaType}
               tapHoldBtnURL={this.props.tapHoldBtnURL}
               iosTapHoldText={this.props.iosTapHoldText}
               hideTapHoldButtonWhenPossible={this.props.hideTapHoldButtonWhenPossible}
               saveButtonImageUrl={saveButtonImageUrl}
               retakeButtonImageUrl={retakeButtonImageUrl}
               shareButtonImageUrl={this.props.shareButtonImageUrl}
               shareText={this.props.shareText}
            />;
      }

      let preview = null;
      if(mediaType === 'image' && this?.props?.videoSrc?.src){
         preview =
            <img
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
            />;
      }
      else if(mediaType === 'video'){
         preview =
            <video
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
               width={this.props.contentWidth}
               height={this.props.contentHeight}
               playsInline={true}
               loop={true}
               controls={this.props.showVideoControls ?? false}
               autoPlay={true}
            />;
      }

      if(this.props.videoSrc) {
         return (
            <div className="review-screen screen">
               <Background userData={this.props.userData} elements={this.props.backgroundElements} />
               <div id={'photoboothReviewScreenLoading'}>
                  <div className={'bv-lds-ring'}>
                     <div/>
                     <div/>
                     <div/>
                     <div/>
                  </div>
               </div>
               <div className="preview-image-container">
                  {preview}
                  {
                     this.props.previewImageFrame &&
                     <img id={'photoboothReviewScreenPreviewFrame'} src={resolveUrl(this.props.previewImageFrame)} />
                  }
               </div>
               <CustomElementsList
                  userData={this.props.userData}
                  customElements={this.props.elements}
               />
               <div className={'send-buttons'}>
                  {
                     this?.props?.userVariables?.email &&
                     <EmailButton
                        onClickEmail={() => this.onClickEmail()}
                        emailButtonImageUrl={this.props.emailButtonImageUrl}
                        emailButtonText={this.props.emailButtonText}
                     />
                  }
                  {
                     this?.props?.userVariables?.phone &&
                     <SMSButton
                        onClickSMS={() => this.onClickSMS()}
                        smsButtonImageUrl={this.props.smsButtonImageUrl}
                        smsButtonText={this.props.smsButtonText}
                     />
                  }

               </div>
               { directDownloadScreen }
               <NavigationButtons {...this.props}/>
            </div>
         );
      }

      return null;
   }
}

function DefaultDirectDownloadScreen(props){
   return (
      <div id={'defaultDirectDownloadScreen'} className={'direct-download-screen'} >
         <RetakeButton {...props} />
         {
            isMobile() &&
            <SaveButton {...props} />
         }
         {
            isMobile() &&
            <ShareButton {...props} />
         }
      </div>
   );
}

function IOSDirectDownloadScreen(props){
   return (
      <div id={'iosDirectDownloadScreen'} className={'direct-download-screen'} >
         {
            props.mediaType === 'image' &&
            <div className={'ios-direct-download-screen-row'}>
               {
                  props.iosTapHoldText && ((isIOS() & getOS().version.major < 15) || !props.hideTapHoldButtonWhenPossible)  &&
                  <div
                     id={'divTapHold'}
                  >
                     {props.iosTapHoldText}
                  </div>
               }
               {
                  props.tapHoldBtnURL && ((isIOS() & getOS().version.major < 15) || !props.hideTapHoldButtonWhenPossible)  &&
                  <img
                     id={'btnTapHold'}
                     crossOrigin={'anonymous'}
                     src={resolveUrl(props.tapHoldBtnURL)}
                  />
               }
            </div>
         }
         <div className={'ios-direct-download-screen-row'}>
            <RetakeButton {...props} />
            {
               SystemInfo.isIOS() && SystemInfo.getOS().version.major >= 15 &&
               <ShareButton {...props} />
            }
            {
               props.iosNextText &&
               <button className={'outline-button ios-next'} onClick={() => props.onClickSave()}>
                  {props.iosNextText}
               </button>
            }
         </div>
      </div>
   );
}

function RetakeButton(props){
   if(props.retakeButtonImageUrl){
      return (
         <button
            className={'retake-button'}
            onClick={() => props.onClickGoBack()}
         >
            <img className={'retake-button-image'} src={resolveUrl(props.retakeButtonImageUrl)} />
         </button>
      );
   }
   else if(props.retakeText) {
      return (
         <button className={'retake-button'} onClick={() => props.onClickGoBack()}>
            {props.retakeText}
         </button>
      );
   }
   return null;
}

function SaveButton(props){
   if(props.saveButtonImageUrl){
      return (
         <button
            className={'save-button'}
            onClick={() => props.onClickSave()}
         >
            <img className={'save-button-image'} src={resolveUrl(props.saveButtonImageUrl)} />
         </button>
      );
   }
   else if(props.saveText) {
      return (
         <button className={'outline-button save'} onClick={() => props.onClickSave()}>
            {props.saveText}
         </button>
      );
   }
   return null;
}

function ShareButton(props){
   if(props.shareButtonImageUrl){
      return (
         <button
            className={'share-button'}
            onClick={() => props.onClickShare()}
         >
            <img className={'share-button-image'} src={resolveUrl(props.shareButtonImageUrl)} />
         </button>
      );
   }
   else if(props.shareText) {
      return (
         <button className={'outline-button share'} onClick={() => props.onClickShare()}>
            {props.shareText}
         </button>
      );
   }
   return null;
}

function EmailButton(props){
   if(props.emailButtonImageUrl){
      return (
         <button
            className={'email-button'}
            onClick={() => props.onClickEmail()}
         >
            <img className={'email-button-image'} src={resolveUrl(props.emailButtonImageUrl)} />
         </button>
      );
   }
   else if(props.emailButtonText) {
      return (
         <button className={'email-button'} onClick={() => props.onClickEmail()}>
            {props.emailButtonText}
         </button>
      );
   }
   return null;
}

function SMSButton(props){
   if(props.smsButtonImageUrl){
      return (
         <button
            className={'sms-button'}
            onClick={() => props.onClickSMS()}
         >
            <img className={'sms-button-image'} src={resolveUrl(props.smsButtonImageUrl)} />
         </button>
      );
   }
   else if(props.smsButtonText) {
      return (
         <button className={'sms-button'} onClick={() => props.onClickSMS()}>
            {props.smsButtonText}
         </button>
      );
   }
   return null;
}