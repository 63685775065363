import {CustomElementsList} from './customElementsList.jsx';
import React from 'react';

export function Background(props){
   if(props.elements){
      return (
         <div className={'background'}>
            <CustomElementsList userData={props.userData} customElements={props.elements} />
         </div>
      );
   }

   return null;
}