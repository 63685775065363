/* eslint-disable prefer-promise-reject-errors */
function onLoad (request, resolve, reject, url) {
   if (request.status >= 200 && request.status < 400) {
      const returnedData = JSON.parse(request.responseText);
      if (returnedData.responseType === 'error') {
         reject(returnedData.message);
      } else if (returnedData.responseType === 'success') {
         resolve(returnedData.data);
      } else {
         resolve(returnedData);
      }
   } else {
      console.log('no access to ' + url);
      reject();
   }
}

function onError (url) {
   if(window.BVExtras?.isWeb) {
      alert('Could not connect to server');
   }
   console.log('no access to ' + url);
}

export function post (url, data) {
   return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open('POST', url);
      request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');

      request.onload = () => { onLoad(request, resolve, reject, url); };

      request.onerror = () => {
         onError(url);
         reject();
      };
      request.send(JSON.stringify(data));
   });
}

export function get (url) {
   return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open('GET', url);

      request.onload = () => { onLoad(request, resolve, reject); };

      request.onerror = () => {
         onError(url);
         reject();
      };

      request.send();
   });
}

export function upload (url, data, additionalData, progressCallback) {
   return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append('data', data);
      fd.append('additionalData', JSON.stringify(additionalData));
      const request = new XMLHttpRequest();
      request.upload.addEventListener('progress', progressCallback, false);
      request.open('POST', url, true);
      request.send(fd);
      request.onload = () => { onLoad(request, resolve, reject, url); };
   });
};