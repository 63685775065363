import React from 'react';
import {Background} from '../background.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {resolveUrl} from '../../utils.js';


export function PdfViewScreen(props){
   return (
      <div className={'pdf-view-screen screen ' + props.styles}>
         <div className={'pdf-view-screen-content'}>
            <iframe className={'pdf-view-document'} src={resolveUrl(props.pdfUrl)} />
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <NavigationButtons
               {...props}
            />
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}