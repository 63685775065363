import {IImageProcessorStage} from './IImageProcessorStage.js';
import vertexShader from '../../shaders/color-balance-vertex.glsl';
import fragmentShader from '../../shaders/color-balance-fragment.glsl';
import * as THREE from 'three';

export class ColorLevelSet extends IImageProcessorStage{
   constructor() {
      super();
      this.renderer = null;
      this.material = null;
      this.canvas = null;

      if(!window.BV){
         window.BV = {};
      }
      if(!window.BV.filters) {
         window.BV.filters = {};
      }

      if(!window.BV.filters.colorLevelSet) {
         this.levelsWorker = new Worker('./levelsWorker.js');
         window.BV.filters.colorLevelSet = {
            worker: this.levelsWorker,
            data: {
               levelsResults: {
                  darkPoint: 0,
                  lightPoint: 1,
               }
            },
            awaitingResults: false
         };

         this.levelsWorker.onmessage = (e) => {
            const { data } = e;
            switch (data.type) {
               case 'levelsResults':

                  window.BV.filters.colorLevelSet.data.levelsResults = data.levelsResults;
                  this.setLevels();
                  window.BV.filters.colorLevelSet.awaitingResults = false;
                  break;
               default:
                  console.log(`No handler for message of type ${data.type}`);
            }
         };
      }
      else {
         this.levelsWorker = window.BV.filters.colorLevelSet.worker;
      }
   }

   init(source){
      this.renderer = new THREE.WebGLRenderer({
         alpha: true
      });
      this.canvas = this.renderer.domElement;

      //document.body.append(this.canvas);

      this.width = source.element.videoWidth || source.element.naturalWidth || source.element.width;
      this.height = source.element.videoHeight || source.element.naturalHeight || source.element.height;
      this.scene = new THREE.Scene();
      this.camera = new THREE.OrthographicCamera(
         this.width / - 2, this.width / 2, this.height / 2, this.height / - 2, 1, 1000
      );
      this.scene.add( this.camera );

      const geometry = new THREE.PlaneGeometry( this.width, this.height );

      this.texture = null;


      if(source.element instanceof HTMLImageElement) {
         this.texture = new THREE.Texture(source.element);
         this.texture.needsUpdate = true;
      } else if (source.element instanceof HTMLVideoElement) {
         this.texture = new THREE.VideoTexture(source.element);
         this.texture.format = THREE.RGBAFormat;
         this.texture.needsUpdate = true;
      } else if(source.element instanceof HTMLCanvasElement) {
         this.texture = new THREE.CanvasTexture(source.element);
         this.texture.needsUpdate = true;
      } else {
         throw new Error('Unknown source element type');
      }

      this.material = new THREE.ShaderMaterial({
         transparent: true,
         uniforms: {
            tLayer: { value: this.texture },
            uDarkPoint: { value: 1 - window.BV.filters.colorLevelSet.data.levelsResults.lightPoint },
            uLightPoint: { value: 1 - window.BV.filters.colorLevelSet.data.levelsResults.darkPoint },
         },
         vertexShader,
         fragmentShader,
      });

      const plane = new THREE.Mesh( geometry, this.material );
      this.scene.add(plane);
      plane.position.z = -1;

      /*
      this.material.uniforms.tLayer.value = null;
      geometry.dispose();
      texture.dispose();
      */
   }

   run(source){

      requestAnimationFrame(() => this.run(source));

      let {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.drawingOptions;

      if(this.texture instanceof  THREE.VideoTexture){
         this.texture.update();
      }

      this.renderer.setSize(this.width, this.height);
      this.renderer.render(this.scene, this.camera);

      for(let canvas of this.canvasElements) {
         let ctx = canvas.getContext('2d');
         let canvasCtx = ctx;

         canvasCtx.save();
         canvasCtx.clearRect(0, 0, dWidth, dHeight);


         ctx.drawImage(
            this.canvas,
            sx, sy, sWidth, sHeight,
            dx, dy, dWidth, dHeight
         );

         canvasCtx.restore();

      }
   }

   setLevels() {
      // We need to use the inverses of the dark and light points
      // otherwise the players will get brighter when the background is darker and vice versa.
      let {lightPoint, darkPoint} = window.BV.filters.colorLevelSet.data.levelsResults;
      if(this.material) {
         this.material.uniforms.uDarkPoint.value = 1 - lightPoint;
         this.material.uniforms.uLightPoint.value = 1 - darkPoint;
      }
   }

   async findLevels(referenceElement){
      // Wait 1 second just to give the camera time to auto-adjust if it wants
      window.BV.filters.colorLevelSet.awaitingResults = true;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const levelsCanvas = document.createElement('canvas');
      levelsCanvas.width = referenceElement.videoWidth;
      levelsCanvas.height = referenceElement.videoHeight;

      const levelsContext = levelsCanvas.getContext('2d');
      levelsContext.drawImage(referenceElement, 0, 0, levelsCanvas.width, levelsCanvas.height);
      const pixelData = levelsContext.getImageData(0, 0, levelsCanvas.width, levelsCanvas.height).data;
      this.levelsWorker.postMessage({
         type: 'findLevels',
         pixelData
      }, [pixelData.buffer]);

      await new Promise(resolve => {
         let interval = setInterval(() => {
            if(!window.BV.filters.colorLevelSet.awaitingResults){
               clearInterval(interval);
               resolve();
            }
         }, 100);
      });

   }

   async onWebcamStart(referenceElement){
      await this.findLevels(referenceElement);
   }

}