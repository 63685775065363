import React from 'react';

export class CaptureVideoScreen extends React.Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   runPhotoboothExperience(){
      let introTimeout = window.BV.photoboothScript.photoTimer;
      let recordingTimeout = window.BV.photoboothScript.delayOut;
      if(!this.props.isInEditor) {
         window.BV.photoBoothExperience.screenshotTimer = null;
         window.BV.photoBoothExperience.run();
         window.setTimeout(() => {
            window.BV.photoBoothExperience.startRecording();
            window.setTimeout(() => {
               window.BV.photoBoothExperience.stopRecording();
            }, recordingTimeout);
         }, introTimeout);
      }
   }

   componentDidMount() {
      this.runPhotoboothExperience();
   }

   render() {
      return (
         <div className="capture-screen">
            {
               this.state.showPlayButton &&
               <div id={'captureScreenPlayDiv'}>
                  <button className={'outline-button'} onClick={() => this.onContinueClicked()}>Continue</button>
               </div>
            }
         </div>
      );
   }
}