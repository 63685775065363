import React from 'react';
import {ScreenType} from '../../shared/dataTypes.js';
import {AgeGateScreen} from './ageGateScreen';
import {WelcomeScreen} from './screens/welcomeScreen.jsx';
import {CaptureScreen} from './captureScreen';
import {ReviewScreen} from './screens/reviewScreen.jsx';
import {ImageReviewScreen} from './screens/imageReviewScreen.jsx';
import {UserSelectionScreen} from './screens/userSelectionScreen.jsx';
import {UserSelectionScreenLegacy} from './screens/userSelectionScreenLegacy.jsx';
import {CustomizationScreen} from './screens/customizationScreen.jsx';
import {ErrorScreen} from './errorScreen';
import {WebcamPermissionScreen} from './webcamPermissionScreen';
import {TextFormScreen} from './screens/textFormScreen';
import {TextFormScreenLegacy} from './screens/textFormScreenLegacy.jsx';
import {BlankScreen} from './screens/blankScreen';
import {BlankImageScreen} from './screens/blankImageScreen.jsx';
import {BlankWebcamScreen} from './screens/blankWebcamScreen.jsx';
import {PhoneConfirmScreen} from './screens/phoneConfirmScreen.jsx';
import {EmailConfirmScreen} from './screens/emailConfirmScreen.jsx';
import {QRConfirmScreen} from './screens/qrConfirmScreen.jsx';
import {PdfViewScreen} from './screens/pdfViewScreen.jsx';
import {IntegrationReviewScreen} from './screens/integrationReviewScreen';
import {CalibrationScreen} from './screens/calibrationScreen';
import {ContactInfoIntegratedFormScreen} from './screens/contactInfoIntegratedFormScreen.jsx';
import {GetMediaScreen} from './screens/getMediaScreen.jsx';
import {CaptureVideoScreen} from './screens/captureVideoScreen.jsx';
import {ManualCaptureScreen} from './screens/manualCaptureScreen.jsx';

export function Screen (props){
   switch (props.type){
      case ScreenType.AgeGate: {
         return <AgeGateScreen {...props} />;
      }
      case ScreenType.Welcome: {
         return <WelcomeScreen {...props} />;
      }
      case ScreenType.Capture: {
         return <CaptureScreen {...props} />;
      }
      case ScreenType.Review: {
         return <ReviewScreen {...props} />;
      }
      case ScreenType.ImageReview: {
         return <ImageReviewScreen {...props} />;
      }
      case ScreenType.Error: {
         return <ErrorScreen {...props} />;
      }
      case ScreenType.CameraPermission: {
         return <WebcamPermissionScreen {...props} />;
      }
      case ScreenType.UserSelection: {
         let newProps = {...props};
         newProps.options = newProps.options || [];
         return <UserSelectionScreen {...newProps} />;
      }
      case ScreenType.UserSelectionLegacy: {
         let newProps = {...props};
         newProps.options = newProps.options || [];
         return <UserSelectionScreenLegacy {...newProps} />;
      }
      case ScreenType.CustomizationScreen: {
         let newProps = {...props};
         newProps.options = newProps.options || [];
         return <CustomizationScreen {...newProps} />;
      }
      case ScreenType.TextForm: {
         return <TextFormScreen {...props} />;
      }
      case ScreenType.TextFormLegacy: {
         return <TextFormScreenLegacy {...props} />;
      }
      case ScreenType.Blank: {
         return <BlankScreen {...props} />;
      }
      case ScreenType.BlankImage: {
         return <BlankImageScreen {...props} />;
      }
      case ScreenType.BlankWebcam: {
         return <BlankWebcamScreen {...props} />;
      }
      case ScreenType.PdfView: {
         return <PdfViewScreen {...props} />;
      }
      case ScreenType.PhoneConfirmScreen: {
         return <PhoneConfirmScreen {...props} />;
      }
      case ScreenType.EmailConfirmScreen: {
         return <EmailConfirmScreen {...props} />;
      }
      case ScreenType.IntegrationReviewScreen: {
         return <IntegrationReviewScreen {...props} />;
      }
      case ScreenType.CalibrationPage: {
         return <CalibrationScreen {...props} />;
      }
      case ScreenType.QRConfirmScreen: {
         return <QRConfirmScreen {...props} />;
      }
      case ScreenType.ContactInfoIntegratedFormScreen: {
         return <ContactInfoIntegratedFormScreen {...props} />;
      }
      case ScreenType.GetMediaScreen: {
         return <GetMediaScreen {...props} />;
      }
      case ScreenType.CaptureVideo: {
         return <CaptureVideoScreen {...props} />;
      }
      case ScreenType.ManualCaptureScreen: {
         return <ManualCaptureScreen {...props} />;
      }
      default: return null;
   }
}

