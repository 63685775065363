import React from 'react';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';

export class BlankImageScreen extends React.Component {
   constructor(props) {
      super(props);
      this.canvasRef = React.createRef();
      this.videoRef = React.createRef();

      this.onClickGoBack = () => this.props.goToPreviousScreen();
      this.state = {
         initialized: false,
      };
   }

   async componentDidMount() {
      if(this.props.videoSrc.type.includes('video')){
         try {
            await document.getElementById('review-canvas').play();
         }
         catch (e){
            console.warn(e);
         }
      }

      //this probably doesn't belong in the blank template, probably better to make a confirm page template later
      if(!this.props.debugMode) {
         window.setTimeout(() => {
            this.props.goToScreen(0);
         }, 3000);
      }
   }

   render() {
      let mediaType = 'image';
      if(this.props.videoSrc.type.includes('video')){
         mediaType = 'video';
      }

      let preview = null;
      if(mediaType === 'image'){
         preview =
            <img
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
            />;
      }
      else if(mediaType === 'video'){
         preview =
            <video
               src={window.URL.createObjectURL(this.props.videoSrc.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
               width={this.props.contentWidth}
               height={this.props.contentHeight}
               playsInline={true}
               loop={true}
            />;
      }

      //give image/video blob a small amount of time to display before hiding photobooth to reduce/remove flicker
      setTimeout(() => {
         window?.BV?.photoBoothExperience?.stop?.();
      }, 100);

      if(this.props.videoSrc) {
         return (
            <div className="blank-image-screen screen">
               <Background userData={this.props.userData} elements={this.props.backgroundElements} />
               <div id={'photoboothBlankImageScreenLoading'}>
                  <div className={'bv-lds-ring'}>
                     <div/>
                     <div/>
                     <div/>
                     <div/>
                  </div>
               </div>
               <div className="preview-image-container">
                  {preview}
               </div>
               <CustomElementsList
                  userData={this.props.userData}
                  customElements={this.props.elements}
               />
               <NavigationButtons {...this.props}/>
            </div>
         );
      }

      return null;
   }
}