import React, {useEffect, useState} from 'react';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {OnScreenKeyboard} from '../onScreenKeyboard';
import {isMobile} from '../../systeminfo.js';
import {resolveUrl} from '../../utils.js';
import {TextFormFieldType} from '../../../shared/dataTypes.js';
import * as Utils from "../../utils.js";

function FormItem(props){
   let onChange = (val) => {
      if(props.type === TextFormFieldType.Phone){
         val = val.replace(/[^0-9]/g, '');
      }
      props.onChange(val);
   };

   let inputType = 'text';
   //osk can't get caret if inputType is not text, so keep it text on desktop.
   if(isMobile()) {
      if(props.type === TextFormFieldType.Email) {
         inputType = 'email';
      }
      if(props.type === TextFormFieldType.Phone) {
         inputType = 'tel';
      }
      if(props.type === TextFormFieldType.Number) {
         inputType = 'number';
      }
      if(props.type === TextFormFieldType.ZipCode) {
         inputType = 'number';
      }
   }


   return (
      <div className={'text-form-screen-item'}>
         <label className={props.labelClass}>{props.label}</label>
         <input
            onChange={(e) => onChange(e.target.value)}
            className={props.inputClass}
            type={inputType}
            value={props.userVariables[props.variableName] || ''}
            onFocus={() => props.onFocus()}
            id={props.id || props.uuid}
            placeholder={props.label}
         />
      </div>
   );
}

function ImageCheckbox(props){
   const [checked, setChecked] = useState(props.defaultValue ?? false);


   useEffect(() => {
      props.onChange();
   }, [checked]);

   let onClick = () => {
      setChecked(!checked);
   };

   if(checked){
      return (
         <div className={'image-checkbox checked'} onClick={() => onClick()}>
            <img
               src={resolveUrl(props.checkedImageUrl)}
               className={'agree-checkbox'}
               checked={true}
               id={props.uuid}
            />
            <span>{props.text}</span>
         </div>
      );
   }
   return (
      <div className={'image-checkbox unchecked'} onClick={() => onClick()}>
         <img
            src={resolveUrl(props.uncheckedImageUrl)}
            className={'agree-checkbox'}
            checked={false}
            id={props.uuid}
         />
         <span>{props.text}</span>
      </div>
   );
}

function AgreeCheckboxes(props){
   return (
      <div className={'text-form-screen-agree-checkboxes'}>
         {
            props.items.map((item) => {
               if(item.checkedImageUrl && item.uncheckedImageUrl){
                  return (
                     <ImageCheckbox
                        {...item}
                        key={item.text}
                        onChange={() => props.onChange()}
                     />
                  );
               }
               return (
                  <div key={item.text} >
                     <input
                        type={'checkbox'}
                        className={'agree-checkbox'}
                        onChange={(e) => props.onChange(item.variableName, e.target.checked)}
                        id={item.uuid}
                        defaultChecked={item.defaultValue ?? false}
                     />
                     <label htmlFor={item.uuid}>
                        {item.text}
                     </label>
                  </div>
               );
            })
         }
      </div>
   );
}

function TermsConditions(props){
   return (
      <div className={'text-form-screen-terms-conditions-div'}>
         {
            props.items.map(item => {
               return (
                  <div key={item.text}>
                     <a
                        className={'text-form-screen-terms-conditions-a'}
                        href={item.url}
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        {item.text}
                     </a>
                  </div>

               );
            })
         }
      </div>
   );
}

/**
 *
 * @param {TextFormScreenScript} props
 * @returns {JSX.Element}
 * @constructor
 */
export function ContactInfoIntegratedFormScreen(props){

   const [enableNextButton, setEnableNextButton] = useState(false);

   let checkCanProceed = () => {
      let canProceed = true;

      let emailRegex = /.+@.+\..+/;

      let textFormFields = props.textFormFields ?? [];
      for(let input of (textFormFields)){
         //only check if it's required, or if the user has entered in something
         if(input.isRequired || props.userVariables[input.variableName]) {
            if(input.type === TextFormFieldType.Email) {
               let isValid = emailRegex.test(props.userVariables[input.variableName]);
               if(!isValid) {
                  canProceed = false;
               }
            }
            else if(input.type === TextFormFieldType.Phone) {
               let isValid = props.userVariables[input.variableName]?.length >= 10;
               if(!isValid) {
                  canProceed = false;
               }
            }
            else if(input.type === TextFormFieldType.ZipCode) {
               let isValid = props.userVariables[input.variableName]?.length === 5;
               if(!isValid) {
                  canProceed = false;
               }
            }
            else if(input.type === TextFormFieldType.Text) {
               let isValid = props.userVariables[input.variableName]?.trim()?.length > 0;
               if(!isValid) {
                  canProceed = false;
               }
            }
         }
      }

      let checkboxElements = Array.from(document.getElementsByClassName('agree-checkbox'));

      let allChecked = checkboxElements.filter(x => {
         let isOptional = props.agreeCheckboxes.find(y => y.uuid === x.id)?.isOptional;
         if(isOptional){
            return false;
         }
         else {
            return !x.checked;
         }
      }).length === 0;

      if(!allChecked){
         canProceed = false;
      }

      setEnableNextButton(canProceed);
   };

   let updateCheckboxVariables = () => {
      let checkboxElements = Array.from(document.getElementsByClassName('agree-checkbox'));
      for(let el of checkboxElements){
         let variableName = props.agreeCheckboxes.find(x => x.uuid === el.id)?.variableName;
         let val = el.checked;
         if(variableName && props.userVariables[variableName] !== val){
            props?.setUserVariable?.(variableName, val);
         }
      }
   };

   useEffect(() => {
      updateCheckboxVariables();
      checkCanProceed();
   });

   let onFormItemChange = (variableName, val) => {
      props?.setUserVariable?.(variableName, val);
   };

   let onCheckboxChange = (variableName, val) => {
      updateCheckboxVariables();
      checkCanProceed();
   };

   let goToNextScreen = () => {
      let pickupUrl = window.location.href.replace('contactInfo','getMedia');
      if(props.contactInfoMethod === 'email') {
         if(!props.userVariables.email){
            console.error('email not defined');
         }

         BVExtras.sendEmail(props.userVariables.email, {url: pickupUrl});
      }

      if(props.contactInfoMethod === 'phone') {
         if(!props.userVariables.phone){
            console.error('email not defined');
         }

         BVExtras.sendSMS(props.userVariables.phone, window.BV.photoboothScript.smsText + pickupUrl);
      }

      if(props.formstackConfig?.genericConfig) {
         let isRequirementFulfilled = true;
         if(props.formstackConfig.genericConfig.requirementVariable){
            isRequirementFulfilled = props.userVariables[props.formstackConfig.genericConfig.requirementVariable];
         }
         if(isRequirementFulfilled) {
            let populatedFields = Utils.populateFormstackData(
               props.formstackConfig.genericConfig.fields,
               props.userVariables
            );

            BVExtras.postFormstack(
               props.formstackConfig.apiToken,
               props.formstackConfig.genericConfig.idForm,
               populatedFields
            );
         }
      }
      props.goToNextScreen();
   };

   return (
      <div className={'text-form-screen screen ' + props.styles}>
         <div className={'text-form-screen-content'}>
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <div className={'text-form-screen-options'}>
               {
                  (props.textFormFields || []).map((x, ind) => {
                     return (
                        <FormItem
                           key={`text-form-screen-options-${x.uuid}`}
                           onChange={(val) => onFormItemChange(x.variableName, val)}
                           onFocus={() => {}}
                           userVariables={props.userVariables}
                           {...x}
                        />
                     );
                  })
               }
            </div>
            <AgreeCheckboxes
               items={props.agreeCheckboxes || []}
               onChange={(variableName, val) => onCheckboxChange(variableName, val)}
            />
            <TermsConditions items={props.termsAndConditions || []} />
            <div className={'text-form-screen-footer'}>
               <NavigationButtons
                  {...props}
                  goToNextScreen={() => {
                     goToNextScreen();
                  }}
                  disableNextButton={!enableNextButton}
               />
            </div>

         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}