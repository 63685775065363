import React from 'react'

export function AgeGateScreen (props){

   let onVerificationChange = (e) => {
      let nextTabFocus = {
         'ageVerificationMonth': 'ageVerificationDay',
         'ageVerificationDay': 'ageVerificationYear',
      }
      if(e.target.value.length === 2){
         let nextTab = document.getElementById(nextTabFocus[e.target.id]);
         if(nextTab)
            nextTab.focus()
      }
   }

   let onVerificationKeyUp = (e) => {
      if(e.key === 'Enter'){
         onClickSubmit();
      }
   }
   let onVerificationInput = e => {
      e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
   }

   let onClickSubmit = () => {
      let day = parseInt(document.getElementById('ageVerificationDay').value);
      let month = parseInt(document.getElementById('ageVerificationMonth').value) - 1;
      let year = parseInt(document.getElementById('ageVerificationYear').value);

      if(isNaN(day) || isNaN(month) || isNaN(year)){
         return;
      }
      let enteredDate = new Date(year, month, day);
      let validDate = new Date();
      validDate.setFullYear(validDate.getFullYear() - 21);
      if(enteredDate < validDate){
         props.goToNextScreen();
      }
      else{
         window.location.href = 'https://www.consumer.ftc.gov/features/feature-0028-we-dont-serve-teens';
      }
   }

   return (
      <div className="preliminary-screen screen">
         <div className={'age-check-text'}>
            YOU MUST BE OF LEGAL DRINKING AGE TO ENTER THIS SITE.
         </div>
         <div>
            <input
               id={'ageVerificationMonth'}
               onChange={onVerificationChange}
               onKeyUp={onVerificationKeyUp}
               onInput={onVerificationInput}
               className={'age-verification-input sm'}
               type={'number'}
               placeholder={'MM'}
               maxLength={2}
            />
            <input
               id={'ageVerificationDay'}
               onChange={onVerificationChange}
               onKeyUp={onVerificationKeyUp}
               onInput={onVerificationInput}
               className={'age-verification-input sm'}
               type={'number'}
               placeholder={'DD'}
               maxLength={2}
            />
            <input
               id={'ageVerificationYear'}
               onChange={onVerificationChange}
               onKeyUp={onVerificationKeyUp}
               onInput={onVerificationInput}
               className={'age-verification-input lg'}
               type={'number'}
               placeholder={'YYYY'}
               maxLength={4}
            />
         </div>
         <div>
            <button id={'ageVerificationSubmit'} className={'outline-button'} onClick={onClickSubmit}>SUBMIT</button>
         </div>
         <div id={'legalInfo'}>
            Crown imports supports the century councils fight against drinking and drunk driving.
            To learn more, visit their website at: <a href={'http://www.centurycouncil.org/'}>www.centurycouncil.org</a>
            <br />
            Relax responsibly<sup>&reg;</sup>. Imported by &copy;2020 Crown Imports, Chicago, IL
            <br />
            Our <a href={'https://www.coronausa.com/pages/privacy-policy'}>Privacy Policy </a>
            and <a href={'https://www.coronausa.com/pages/terms-conditions'}>Terms & Conditions</a> have changed.
            By using this website, you agree to the Privacy Policy and Terms & Conditions of use.
            <br />
            Also see <a href={'https://www.coronausa.com/pages/contact-us'}>do not sell my information</a>.
            <br />
            No Purchase Necessary. 21+ Rules: <a href={'http://chshappyholidays.dja.com/'}>CHSHappyHolidays.dja.com</a>
         </div>
      </div>
   );
}

