import React from 'react';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';

function LensNavigation(props){
   if(window.BV.lensIntegration && window.BV.lensIntegration.availableLenses.length > 1){
      return (
         <div className={'lens-integration-navigation'}>
            {
               window.BV.lensIntegration.availableLenses.length > 2 &&
               <div className={'button previous-button'} onClick={() => window.BV.lensIntegration.loadPreviousLens()}>
                  Previous
               </div>
            }
            <div className={'button next-button'} onClick={() => window.BV.lensIntegration.loadNextLens()}>
               Next
            </div>
         </div>
      );
   }
   else {
      return null;
   }
}

export class BlankWebcamScreen extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   async componentDidMount() {
      window.BV.photoBoothExperience?.runOnlyWebcam();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '0';
   }

   componentWillUnmount(){
      window.BV.photoBoothExperience?.stop();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '';
   }

   render() {
      return (
         <div className="blank-webcam-screen screen">
            <Background userData={this.props.userData} elements={this.props.backgroundElements} />
            <CustomElementsList
               userData={this.props.userData}
               customElements={this.props.elements}
            />
            <NavigationButtons {...this.props}/>
            <LensNavigation />
         </div>
      );
   }
}