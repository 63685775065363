import React, {useEffect} from 'react';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {
   getSelectionBase, Option, processSelection, mapValues, getSelectionCountBucket
} from './userSelectionScreen.jsx';


/**
 *
 * @param {CustomizationScreen} props
 * @returns {JSX.Element}
 * @constructor
 */
export function CustomizationScreen(props){

   useEffect(async () => {
      window.BV.photoBoothExperience.removeAllElements();
      window.BV.photoBoothExperience.disconnectFromRootElement();
      let newRootElement = document.getElementById('customizationScreenPreviewContainer');
      window.BV.photoBoothExperience.setRootElement(newRootElement);
      window.BV.photoBoothExperience.onResize();
      await window.BV.photoBoothExperience.initSources();
      window.BV.photoBoothExperience.run();
   }, [props.targetElements]);


   let canContinue = false;

   //empty string is ok, undefined is not
   if(props.userVariables[props?.targetElements?.[0]?.items?.[0]?.idElement] !== undefined){
      canContinue = true;
   }

   let visibleOptions = props.options.filter(x => !x.hidden && !x.isArchived);

   let onSelect = async (selectionIndex) => {
      let selectionOption = props.options[selectionIndex];
      if(selectionOption.disabled){
         return;
      }

      let curSelections = props.userSelectionsByScreen[props.uuid] || [];

      let newSelections = processSelection(curSelections, selectionOption, props.targetElements.length);

      props.setUserSelection(props.uuid, newSelections);


      //map the values from newSelection onto the variables

      let newVals = {};
      for(let i = 0; i < props.targetElements.length; i++){
         let targetElementGroup = props.targetElements[i].items;
         let values = mapValues(newSelections[i]?.values || []);
         for(let j = 0; j < targetElementGroup.length; j++){
            newVals[targetElementGroup[j].idElement] = values?.[i]?.[j];
            //await props.setUserVariable(targetElementGroup[j].idElement, values?.[i]?.[j]);
         }
      }
      await props.setUserVariables(newVals);

   };

   let screenStyles = props.styles;
   let optionParentStyles = 'customization-screen-options';

   let foundBucket = getSelectionCountBucket(visibleOptions.length);
   optionParentStyles += ` customization-screen-options-${foundBucket}orless`;
   screenStyles += ` customization-screen-options-${foundBucket}orless`;

   return (
      <div className={'customization-screen screen ' + screenStyles}>
         <div className={'customization-screen-video-container'} >
            <video id={'userSelectionScreenVideo'} src={''} playsInline muted> </video>
            <video id={'userSelectionScreenVideo2'} src={''} playsInline muted> </video>
         </div>
         <div className={'customization-screen-content'} >
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <div id={'customizationScreenPreviewContainer'}></div>
            <div className={optionParentStyles}>
               {
                  props.options.map((x, ind) => {
                     if(x.hidden || x.isArchived)
                        return (
                           <div key={ind}/>
                        );

                     let values = x.values.map(y => {
                        return y.map(z => {
                           let val = z.value;
                           if(z.appendVideoExtensionToValue){
                              //val += videoType;
                           }
                           return val;
                        });
                     });

                     let selected = false;
                     for(let i = 0; i < props.targetElements.length; i++){
                        let group = props.targetElements[i];
                        let curVariables = {...props?.userVariables};
                        Object.keys(curVariables).forEach(key => {
                           curVariables[key] = getSelectionBase(curVariables[key]);
                        });
                        let savedValuesArray = group.items.map(x => curVariables[x.idElement]);
                        let groupVariables = (values?.[i] || []).map(x => getSelectionBase(x));
                        if(savedValuesArray.length === groupVariables.length){
                           selected = true;
                           for(let i = 0; i < savedValuesArray.length; i++){
                              if(savedValuesArray[i] !== groupVariables[i]){
                                 selected = false;
                                 break;
                              }
                           }
                        }
                        if(selected){
                           break;
                        }
                     }

                     return (
                        <Option
                           key={ind}
                           onClick={async () => await onSelect(ind)}
                           selected={selected}
                           optionCount={visibleOptions.length}
                           {...x}
                        />
                     );
                  })
               }
            </div>
            <div className={'customization-screen-footer'}>
               <NavigationButtons
                  {...props}
                  goToNextScreen={() => {
                     props?.customFunctions?.onUserSelectSubmit?.(props.userVariables);
                     props.goToNextScreen();
                  }}
                  disableNextButton={!canContinue}
               />
            </div>
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}