import React from 'react';
import {resolveUrl} from '../utils.js';

export function NextButton(props){

   let goToNextScreen = () => {
      if(!props.disableNextButton){
         props.goToNextScreen();
      }
   };

   if(props.nextButtonImageUrl){
      return (
         <button
            tabIndex={0}
            className={'next-button ' + (props.disableNextButton ? 'disabled' : '')}
            onClick={() => goToNextScreen()}
         >
            <img className={'next-button-image'} src={resolveUrl(props.nextButtonImageUrl)} />
         </button>
      );
   }
   else if(props.nextButtonText){
      return (
         <button
            tabIndex={0}
            className={'next-button ' + (props.disableNextButton ? 'disabled' : '')}
            onClick={() => goToNextScreen()}
         >
            {props.nextButtonText}
         </button>
      );
   }
   else {
      return null;
   }
}

export function BackButton(props){
   if(props.backButtonImageUrl){
      return (
         <button
            className={'back-button'}
            onClick={() => props.goToPreviousScreen()}
         >
            <img className={'back-button-image'} src={resolveUrl(props.backButtonImageUrl)} />
         </button>
      );
   }
   else if(props.backButtonText){
      return (
         <button className={'back-button'} onClick={() => props.goToPreviousScreen()}>
            {props.backButtonText}
         </button>
      );
   }
   else {
      return null;
   }
}

export function NavigationButtons(props){
   return (
      <div className={'navigation-buttons'}>
         <BackButton {...props} />
         <NextButton {...props} />
      </div>
   );
}