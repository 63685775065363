import React from 'react';
import Video from './video';
import {resolveUrl} from '../utils.js';

export function InactiveCountdown(props) {

   if(props.visible){
      return (
         <div>
            <Video
               autoPlay={true}
               style={{zIndex:100}}
               className={'inactivity-video'}
               width={props.contentWidth}
               height={props.contentHeight}
               loop={false}
               muted={false}
               onEnded={props.onEnded}
               src={{
                  src: resolveUrl(props.videoUrl),
                  type: 'video/webm'
               }}
            />
         </div>
      );
   }
   else return null;
}
