import React from 'react';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {Button} from '@mui/material';

export class CalibrationScreen extends React.Component {
   constructor(props) {
      super(props);

      this.state = {};
   }

   async componentDidMount() {
      window.BV.photoBoothExperience?.runOnlyWebcam();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '0';
   }

   componentWillUnmount(){
      window.BV.photoBoothExperience?.stop();
      document.getElementById('bvPhotoBoothRoot').style.zIndex = '';
   }

   render() {
      return (
         <div className="blank-webcam-screen screen">
            <Background userData={this.props.userData} elements={this.props.backgroundElements} />
            <CustomElementsList
               userData={this.props.userData}
               customElements={this.props.elements}
            />
            <NavigationButtons {...this.props}/>
            {
               this.props.showCloseButton &&
               <Button style={{position: "absolute", top: 0, right: 0}}
                  variant={'contained'}
                  onClick={()=> this.props.closeApp?.()}>
                  Close App
               </Button>
            }
         </div>
      );
   }
}