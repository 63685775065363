import React from 'react';
import ReactDOM from 'react-dom';
import App from './react/app.jsx';
import {resolveUrl} from './utils.js';
import Stats from 'stats.js';
import {PhotoboothScript} from '../shared/photoboothScript.js';

let resizeTimeout = null;

function getAppWidthHeight(photoboothScript){
   let clientRects = document.body.getClientRects();

   let appWidth = Math.min(clientRects[0].width, photoboothScript.maxWidth || Number.MAX_SAFE_INTEGER);
   if(photoboothScript.appAspectRatio){
      appWidth = clientRects[0].height * photoboothScript.appAspectRatio;
   }

   return { appWidth, appHeight: clientRects[0].height};
}

export function stop(){
   if(window?.BV?.photoBoothExperience){
      window.BV.photoBoothExperience.delete();
      window.BV.photoBoothExperience = null;
   }
   let reactRoot = document.getElementById('bvReactRoot');
   ReactDOM.unmountComponentAtNode(reactRoot);
}

export async function start(photoboothScript) {

   photoboothScript = new PhotoboothScript(photoboothScript);

   for (let additionalCssFile of photoboothScript.additionalCssFiles || []) {
      let file = document.createElement('link');
      file.setAttribute('rel', 'stylesheet');
      file.setAttribute('href', resolveUrl(additionalCssFile));
      document.head.appendChild(file);
      await new Promise((resolve, reject) => {
         file.onload = () => {
            resolve();
         };
         file.onerror = (e) => {
            alert('Error loading: ' + additionalCssFile);
            reject();
         };
      });
   }

   for (let additionalJsFile of photoboothScript.additionalJsFiles || []) {
      let file = document.createElement('script');
      file.setAttribute('type', 'module');
      file.setAttribute('src', resolveUrl(additionalJsFile));
      document.head.appendChild(file);
      await new Promise((resolve, reject) => {
         file.onload = () => {
            resolve();
         };
         file.onerror = (e) => {
            alert('Error loading: ' + additionalJsFile);
            reject();
         };
      });
   }

   let {appWidth, appHeight} = getAppWidthHeight(photoboothScript);

   document.documentElement.style.setProperty('--app-height', appHeight + 'px');
   document.documentElement.style.setProperty('--app-width', appWidth + 'px');

   window.addEventListener('resize', () => {
      if (resizeTimeout) {
         clearTimeout(resizeTimeout);
      }
      resizeTimeout = setTimeout(async () => {
         let newDimensions = getAppWidthHeight(photoboothScript);
         document.documentElement.style.setProperty('--app-height', newDimensions.appHeight + 'px');
         document.documentElement.style.setProperty('--app-width', newDimensions.appWidth + 'px');
      }, 1000);
   });


   let existingStatsWrapper = document.getElementById('bvStatsWrapper');
   if (!existingStatsWrapper) {
      let stats = new Stats();
      stats.showPanel(0);

      let statsWrapper = document.createElement('div');
      statsWrapper.className = 'hidden';
      statsWrapper.id = 'bvStatsWrapper';
      stats.dom.removeAttribute('style');

      statsWrapper.appendChild(stats.dom);
      document.body.appendChild(statsWrapper);

      function animate() {
         stats.begin();
         stats.end();
         requestAnimationFrame(animate);
      }

      requestAnimationFrame(animate);

      document.addEventListener('debugModeToggle', (e) => {
         if (e.detail.debugMode) {
            statsWrapper.className = 'stats';
         } else {
            statsWrapper.className = 'hidden';
         }
      });
   }

   let reactRoot = document.getElementById('bvReactRoot');

   ReactDOM.render(
      <React.StrictMode>
         <App
            allowSocialShare={false}
            allowDirectDownload={true}
            emailProjectName={''}
            smsProjectName={''}
            customFunctions={window.BV.customFunctions || {}}
            photoboothScript={photoboothScript}
            inactivityTimeLimit={photoboothScript.inactivityTimeLimit}
            parentElement={reactRoot}
         />
      </React.StrictMode>,
      reactRoot
   );
}
