export class IImageProcessorStage{
   constructor() {
      this.processingInProgress = false;
      this.drawingOptions = {};
      this.canvasElements = [];
   }

   init(){

   }

   run(image){

   }

   setDrawingOptions(options){
      this.drawingOptions = options;
      this.onDrawingOptionsChanged();
   }

   onDrawingOptionsChanged(){

   }

   setCanvasElements(elements){

   }

   async onWebcamStart(){

   }

   async onExperienceStop(){

   }

   async onExperienceStart(){

   }

}