import React from 'react'

export function CustomButtonList(props){

   return (
      <React.Fragment>
         {
            props.customButtons?.map(el => {
               return(
                  <button id={el.id} className={el.class} onClick={(e) => el.onClick(e)} key={el.id}>
                     {el.innerText}
                  </button>
               )
            })
         }
      </React.Fragment>
   );
}