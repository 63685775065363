import React from 'react'
import {CustomElementsList} from "./customElementsList";

export function WebcamPermissionScreen(props){
   if(!props.customElements){
      return(
         <div id={'WebcamPermissionScreen'}>
            TO CONTINUE, WE NEED ACCESS TO YOUR CAMERA
         </div>
      )
   }
   return(
      <div id={'WebcamPermissionScreen'}>
         <CustomElementsList userData={props.userData} customElements={props.customElements} />
      </div>
   )
}