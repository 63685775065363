import React from 'react';

export class CaptureScreen extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         isWebcamPlaying: false,
         showPlayButton: false,
         showErrorScreen: false,
         curScreen: 'main'
      };
   }

   runPhotoboothExperience(){
      if(window.BV.photoBoothExperience) {
         window.BV.photoBoothExperience.run().catch((e) => {
            console.warn(e);
            this.setState({showPlayButton: true});
         }).finally(() => {
            this.setState({
               isWebcamPlaying: true
            });
         });
      }
      else{
         window.setTimeout(() => {
            if(window.BV.photoBoothExperience) {
               window.BV.photoBoothExperience.run().catch((e) => {
                  console.warn(e);
                  this.setState({showPlayButton: true});
               }).finally(() => {
                  this.setState({
                     isWebcamPlaying: true
                  });
               });
            }
         }, 500);
      }
   }

   componentDidMount() {
      this.runPhotoboothExperience();
   }

   componentWillUnmount() {
      setTimeout(() => {
         window?.BV?.photoBoothExperience?.stop?.();
      }, 100);
   }

   render() {
      return (
         <div className="capture-screen">
            {
               this.state.showPlayButton &&
               <div id={'captureScreenPlayDiv'}>
                  <button className={'outline-button'} onClick={() => this.onContinueClicked()}>Continue</button>
               </div>
            }
         </div>
      );
   }
}