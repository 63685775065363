import React from 'react';
import {isIOS, getOS, isMobile} from '../../systeminfo.js';
import * as SystemInfo from '../../systeminfo.js';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {resolveUrl} from '../../utils.js';
import * as AjaxFunctions from '../../ajaxFunctions.js';

let isRegenerating = false;


async function tryGetNewImage(){
   let blob = null;
   let imageUrl = window.BV.imageUrl + '?' + new Date().getTime();
   try {
      blob = await new Promise((resolve, reject) => {
         let xhr = new XMLHttpRequest();
         xhr.open('GET', imageUrl);
         xhr.responseType = 'blob';
         xhr.onerror = () => reject('Network error.');
         xhr.onloadend = () => {
            if(xhr.status === 404 || xhr.status === 403) {
               reject('404');
            }
            if(xhr.status === 0)
               console.warn('cors error');
         };
         xhr.onload = function () {
            if(xhr.status === 200) {
               resolve(xhr.response);
            } else {
               reject('Loading error:' + xhr.statusText);
            }
         };
         xhr.send();
      });
   }
   catch (err) {
      console.warn(err);
   }
   return blob;
}



export class ImageReviewScreen extends React.Component {
   constructor(props) {
      super(props);
      this.canvasRef = React.createRef();

      this.onClickGoBack = () => this.props.goToPreviousScreen();
      this.state = {
         initialized: false,
         mediaSource: this.props.videoSrc
      };
   }

   async componentDidMount() {
      this.doNotSendSessionData = localStorage.getItem('doNotSendSessionData');
      if(this.doNotSendSessionData === 'false')
         this.doNotSendSessionData = false;

      if(window.BVExtras?.isWeb && this.props.webSubmitSessionOnLoad && !this.doNotSendSessionData) {
         let userVariables = this?.props?.userVariables || {};
         userVariables.smsSent = true;
         window.BVExtras?.submitSessionData?.({
            userVariables,
            startTime: this?.props?.sessionStartTime,
            endTime: Date.now()
         });
      }
   }

   onClickEmail() {
      this.props?.customFunctions?.onClickEmail?.(
         this.state.mediaSource.src,
         this.props.userVariables,
         'image'
      );
      if(this.props.emailButtonNextIndex){
         this.props.goToScreen(this.props.emailButtonNextIndex);
      }
   }

   onClickSMS() {
      this.props?.customFunctions?.onClickSMS?.(
         this.state.mediaSource.src,
         this.props.userVariables,
         'image'
      );
      if(this.props.smsButtonNextIndex){
         this.props.goToScreen(this.props.smsButtonNextIndex);
      }
   }

   onClickShare() {

      let file = new File([this.state.mediaSource.src], 'picture.png', {type: this.state.mediaSource.type});
      this.props?.customFunctions?.onSharePicture?.(this.state.mediaSource.src, this.props.userVariables);

      try {
         if(navigator.canShare && navigator.canShare({files: [file]})) {
            navigator.share({
               files: [file],
               title: this.props.shareItemTitle,
               text: this.props.shareItemText,
            })
               .then(() => {
                  console.debug('Share was successful.');
                  if(window.BVExtras?.isWeb && !this.props.webSubmitSessionOnLoad && !this.doNotSendSessionData) {
                     let userVariables = this?.props?.userVariables || {};
                     userVariables.smsSent = true;
                     window?.BVExtras?.submitSessionData?.({
                        userVariables,
                        startTime: this?.props?.sessionStartTime,
                        endTime: Date.now()
                     });
                  }
               })
               .catch((error) => {
                  if(error.name !== 'AbortError') {
                     console.warn('Sharing failed', error);
                     this.props?.customFunctions?.sharingFailed?.();
                     alert('Unable to share. Please reload the page and try again.');
                  }
               });
         } else {
            console.warn('Sharing not supported');
            this.props?.customFunctions?.sharingNotSupported?.();
         }
      }
      catch (e){
         console.warn('Sharing not supported');
         this.props?.customFunctions?.sharingNotSupported?.();
      }
   }

   onClickSave() {
      let dlLink = document.createElement('a');

      dlLink.href = window.URL.createObjectURL(this.state.mediaSource.src);
      dlLink.download = 'picture.png';


      document.body.append(dlLink);
      dlLink.click();
      this.props.goToNextScreen();
   }

   onClickSaveIOS() {
      this.props.goToNextScreen();
   }

   async onClickRegenerate(){
      if(isRegenerating)
         return;

      alert('A new image is being generated, this may take a few minutes.');
      this.setState({
         mediaSource: {}
      });
      let splitUrl = window.location.pathname.split('/');
      let idScript = splitUrl[splitUrl.length - 2];
      let fileName = splitUrl[splitUrl.length - 1];
      await AjaxFunctions.regenerateImage(idScript, fileName);
      isRegenerating = true;

      await new Promise((resolve, reject) => {
         let interval = window.setInterval(async () => {
            let newBlob = await tryGetNewImage();
            if(newBlob){
               this.setState({
                  mediaSource: {
                     type: 'image/png',
                     src: new Blob([newBlob], {type: 'image/png'})
                  }
               });
               window.clearInterval(interval);
               isRegenerating = false;
            }
         },5000);
      });

   }

   render() {
      let saveText = this.props.savePhotoText || this.props.saveText;
      let saveButtonImageUrl = this.props.savePhotoButtonImageUrl || this.props.saveButtonImageUrl;
      let retakeText = this.props.retakePhotoText;
      let retakeButtonImageUrl = this.props.retakePhotoButtonImageUrl;
      let mediaType = 'image';

      let directDownloadScreen =
         <DefaultDirectDownloadScreen
            onClickGoBack={() => this.onClickGoBack()}
            onClickSave={() => this.onClickSave()}
            onClickShare={() => this.onClickShare()}
            retakeText={retakeText}
            saveText={saveText}
            saveButtonImageUrl={saveButtonImageUrl}
            retakeButtonImageUrl={retakeButtonImageUrl}
            shareButtonImageUrl={this.props.shareButtonImageUrl}
            shareText={this.props.shareText}
            mediaSource={this.state.mediaSource}
            regeneratePhotoText={this.props.regeneratePhotoText}
            regeneratePhotoButtonImageUrl={this.props.regeneratePhotoButtonImageUrl}
            onClickRegenerate={() => this.onClickRegenerate()}
         />;

      if(isIOS()){
         directDownloadScreen =
            <IOSDirectDownloadScreen
               onClickGoBack={() => this.onClickGoBack()}
               onClickSave={() => this.onClickSaveIOS()}
               onClickShare={() => this.onClickShare()}
               retakeText={retakeText}
               iosNextText={this.props.iosNextText}
               mediaType={mediaType}
               tapHoldBtnURL={this.props.tapHoldBtnURL}
               iosTapHoldText={this.props.iosTapHoldText}
               hideTapHoldButtonWhenPossible={this.props.hideTapHoldButtonWhenPossible}
               saveButtonImageUrl={saveButtonImageUrl}
               retakeButtonImageUrl={retakeButtonImageUrl}
               shareButtonImageUrl={this.props.shareButtonImageUrl}
               shareText={this.props.shareText}
               regeneratePhotoText={this.props.regeneratePhotoText}
               regeneratePhotoButtonImageUrl={this.props.regeneratePhotoButtonImageUrl}
               onClickRegenerate={() => this.onClickRegenerate()}
            />;
      }
      let preview = <div />;
      if(this.state.mediaSource.src) {
         preview =
            <img
               src={window.URL.createObjectURL(this.state.mediaSource.src)}
               className={'preview-image'}
               id={'review-canvas'}
               ref={this.canvasRef}
            />;
      }


      if(this.state.mediaSource) {
         return (
            <div className="review-screen screen">
               <Background userData={this.props.userData} elements={this.props.backgroundElements} />
               <div id={'photoboothReviewScreenLoading'}>
                  <div className={'bv-lds-ring'}>
                     <div/>
                     <div/>
                     <div/>
                     <div/>
                  </div>
               </div>
               <div className="preview-image-container">
                  {preview}
                  {
                     this.props.previewImageFrame &&
                     <img id={'photoboothReviewScreenPreviewFrame'} src={resolveUrl(this.props.previewImageFrame)} />
                  }
               </div>
               <CustomElementsList
                  userData={this.props.userData}
                  customElements={this.props.elements}
               />
               <div className={'send-buttons'}>
                  {
                     this?.props?.userVariables?.email &&
                     <EmailButton
                        onClickEmail={() => this.onClickEmail()}
                        emailButtonImageUrl={this.props.emailButtonImageUrl}
                        emailButtonText={this.props.emailButtonText}
                     />
                  }
                  {
                     this?.props?.userVariables?.phone &&
                     <SMSButton
                        onClickSMS={() => this.onClickSMS()}
                        smsButtonImageUrl={this.props.smsButtonImageUrl}
                        smsButtonText={this.props.smsButtonText}
                     />
                  }

               </div>
               { directDownloadScreen }
               <NavigationButtons {...this.props}/>
            </div>
         );
      }

      return null;
   }
}

function DefaultDirectDownloadScreen(props){
   return (
      <div id={'defaultDirectDownloadScreen'} className={'direct-download-screen'} >
         <RetakeButton {...props} />
         <RegenerateButton
            regeneratePhotoButtonImageUrl={props.regeneratePhotoButtonImageUrl}
            regeneratePhotoText={props.regeneratePhotoText}
            onClick={() => props.onClickRegenerate()}
         />

         {
            isMobile() &&
            <SaveButton {...props} />
         }
         {
            isMobile() &&
            <ShareButton {...props} />
         }
      </div>
   );
}

function IOSDirectDownloadScreen(props){
   return (
      <div id={'iosDirectDownloadScreen'} className={'direct-download-screen'} >
         {
            props.mediaType === 'image' &&
            <div className={'ios-direct-download-screen-row'}>
               {
                  props.iosTapHoldText && ((isIOS() & getOS().version.major < 15) || !props.hideTapHoldButtonWhenPossible)  &&
                  <div
                     id={'divTapHold'}
                  >
                     {props.iosTapHoldText}
                  </div>
               }
               {
                  props.tapHoldBtnURL && ((isIOS() & getOS().version.major < 15) || !props.hideTapHoldButtonWhenPossible)  &&
                  <img
                     id={'btnTapHold'}
                     crossOrigin={'anonymous'}
                     src={resolveUrl(props.tapHoldBtnURL)}
                  />
               }
            </div>
         }
         <div className={'ios-direct-download-screen-row'}>
            <RetakeButton {...props} />
            <RegenerateButton
               regeneratePhotoButtonImageUrl={props.regeneratePhotoButtonImageUrl}
               regeneratePhotoText={props.regeneratePhotoText}
               onClick={() => props.onClickRegenerate()}
            />
            {
               SystemInfo.isIOS() && SystemInfo.getOS().version.major >= 15 &&
               <ShareButton {...props} />
            }
            {
               props.iosNextText &&
               <button className={'outline-button ios-next'} onClick={() => props.onClickSave()}>
                  {props.iosNextText}
               </button>
            }
         </div>
      </div>
   );
}

function RetakeButton(props){
   if(props.retakeButtonImageUrl){
      return (
         <button
            className={'retake-button'}
            onClick={() => props.onClickGoBack()}
         >
            <img className={'retake-button-image'} src={resolveUrl(props.retakeButtonImageUrl)} />
         </button>
      );
   }
   else if(props.retakeText) {
      return (
         <button className={'retake-button'} onClick={() => props.onClickGoBack()}>
            {props.retakeText}
         </button>
      );
   }
   return null;
}

function RegenerateButton(props){
   if(props.regeneratePhotoButtonImageUrl){
      return (
         <button
            className={'regenerate-button'}
            onClick={() => props.onClick()}
         >
            <img className={'regenerate-button-image'} src={resolveUrl(props.regeneratePhotoButtonImageUrl)} />
         </button>
      );
   }
   else if(props.regeneratePhotoText) {
      return (
         <button className={'regenerate-button'} onClick={() => props.onClick()}>
            {props.regeneratePhotoText}
         </button>
      );
   }
   return null;
}

function SaveButton(props){
   if(props.saveButtonImageUrl){
      return (
         <button
            className={'save-button'}
            onClick={() => props.onClickSave()}
         >
            <img className={'save-button-image'} src={resolveUrl(props.saveButtonImageUrl)} />
         </button>
      );
   }
   else if(props.saveText) {
      return (
         <button className={'outline-button save'} onClick={() => props.onClickSave()}>
            {props.saveText}
         </button>
      );
   }
   return null;
}

function ShareButton(props){
   if(props.shareButtonImageUrl){
      return (
         <button
            className={'share-button'}
            onClick={() => props.onClickShare()}
         >
            <img className={'share-button-image'} src={resolveUrl(props.shareButtonImageUrl)} />
         </button>
      );
   }
   else if(props.shareText) {
      return (
         <button className={'outline-button share'} onClick={() => props.onClickShare()}>
            {props.shareText}
         </button>
      );
   }
   return null;
}

function EmailButton(props){
   if(props.emailButtonImageUrl){
      return (
         <button
            className={'email-button'}
            onClick={() => props.onClickEmail()}
         >
            <img className={'email-button-image'} src={resolveUrl(props.emailButtonImageUrl)} />
         </button>
      );
   }
   else if(props.emailButtonText) {
      return (
         <button className={'email-button'} onClick={() => props.onClickEmail()}>
            {props.emailButtonText}
         </button>
      );
   }
   return null;
}

function SMSButton(props){
   if(props.smsButtonImageUrl){
      return (
         <button
            className={'sms-button'}
            onClick={() => props.onClickSMS()}
         >
            <img className={'sms-button-image'} src={resolveUrl(props.smsButtonImageUrl)} />
         </button>
      );
   }
   else if(props.smsButtonText) {
      return (
         <button className={'sms-button'} onClick={() => props.onClickSMS()}>
            {props.smsButtonText}
         </button>
      );
   }
   return null;
}