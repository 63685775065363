import '../styles/video.css';
import React from 'react';

class Video extends React.Component {
   constructor(props) {
      super(props);

      // Create reference to video element
      this.videoRef = React.createRef();

      // Setup handles to methods that are bound to the current context.
      //this.handleAnimationFrame = this.onAnimationFrame.bind(this);
      //this.handleEnded = this.onEnded.bind(this);
      //this.handleLoadedMetaData = this.onLoadedMetaData.bind(this);
      //this.handleWindowResize = this.onWindowResize.bind(this);

      this.pause = this.pause.bind(this);
      this.play = this.play.bind(this);
   }

   playWithNewSource(src) {

      let video = this.videoRef.current;
      if(this.props.hideOnEnded)
         video.style.opacity = 1;
      video.src = src.src;
      video.type = src.type;

      this.play();
   }

   componentDidMount() {
      var { src } = this.props;

      if (!src)
         return;

      // Get the video element.
      var video = this.videoRef.current;

      // Set the source for the video defined
      // in the given props.
      if (src.length) {
         for (let i = 0; i < src.length; i++) {
            let source = src[i];
            let vidSrc = document.createElement('source');
            vidSrc.src = source.src;
            vidSrc.type = source.type;
            video.appendChild(vidSrc);
         }
      }
      else if (src.src && src.type) {
         video.src = src.src;
         video.type = src.type;
      }

      // Set the size for the video
      // screen before adding it to the DOM.
      //video.style.position = 'absolute';

      // It's already in the DOM
      //document.body.appendChild(video);

      // Get the 2D context of the canvas.
      //this.setState({ video });

      // Wait for the next thread before calling to
      // setup the video event listeners.
      //setTimeout(() => {
      //this.setupListeners();
      //}, 0);
      if (this.props.autoPlay) {
         this.play();
      }
   }
   componentWillUnmount() {
   }

   render() {
      return (
         <div className="Video">
            <video
               className={`${this.props.layer} ${this.props.flip?"flip":""}`}
               style={this.props.style || {}}
               ref={this.videoRef}
               crossOrigin={'anonymous'}
               muted
               loop={this.props.loop ? 'loop' : ''}
               playsInline
               height={this.props.height}
               width={this.props.width}
               onEnded={()=> this.videoEnded()}
            />
         </div>
      );
   }

   onLoadedMetaData(e) {
      if (this.props.autoPlay) {
         this.play();
      }
   }

   pause() {
      if (this.videoRef.current) {
         this.videoRef.current.pause();
      }
   }

   play() {
      if (this.videoRef.current) {
         this.videoRef.current.play();
      }
   }

   stop() {
      if (this.videoRef.current) {
         this.videoRef.current.pause();
         this.videoRef.current.currentTime = 0;
      }
   }
   videoEnded() {
      if(this.props.onEnded)
         this.props.onEnded();
      if(this.props.hideOnEnded)
      {
         //fade out
         var fadeTarget = this.videoRef.current;
         var fadeEffect = setInterval(function () {
            if (!fadeTarget.style.opacity) {
                  fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                  fadeTarget.style.opacity -= 0.2;
            } else {
                  clearInterval(fadeEffect);
            }
         }, 50);
      }
   }
}

export default Video;
