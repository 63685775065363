import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/styles';
import { Button, Collapse, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {ChevronLeft} from '@mui/icons-material';
import {KeyboardArrowUp as KeyboardArrowUpIcon} from '@mui/icons-material';
import {KeyboardArrowDown as KeyboardArrowDownIcon} from '@mui/icons-material';

const DrawerHeader = styled('div')(({ theme }) => ({
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'flex-end',
 }));

function MenuItem(props){

   const expand = (e) => {
      e.stopPropagation();
      props.expandFunction()
   }
   return(
      <ListItem sx={props.sx} button={true} onClick={props.clickFunction} style={{minWidth:'200px'}} selected={props.selected}>
         <ListItemText align={'left'} primary={props.text} />
         {
            props.expandFunction && 
            <IconButton onClick={(e)=> expand(e)}>
               {props.open? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/>}
            </IconButton>
         }
      </ListItem>
   )
}
export function PersistentDrawer(props) {

   const [templateOpen, setTemplateOpen] = useState(false);

   const toggleMenu = () => {
      if(props.toggleMenu);
      props.toggleMenu();
   }
   const gotoHref = (page, addIdUser) => {
      if(window.localStorage.getItem('isAdmin') && addIdUser){
         window.location.href = `/organization/${page}/${window.localStorage.getItem('idUser')}`;
      }
      else
         window.location.href = `/organization/${page}`;
   }
   const gotoAdmin = () => {
      window.localStorage.removeItem('idUser');
      window.localStorage.removeItem('user');
      
      window.location.href = `/admin`;
   }
   function MenuList() {
      return (
         <div>
            {
               window.localStorage.getItem('isAdmin') &&
               <MenuItem text='Back to Admin' clickFunction={() => gotoAdmin()} selected={ props.pagePath ==="admin"}/>
            }
            <MenuItem text='Analytics' clickFunction={() => gotoHref('dashboard', true) } selected={ props.pagePath ==="dashboard"}/>
            <MenuItem text='Themes' clickFunction={() => gotoHref('templates', false)} selected={ props.pagePath ==="templates"}
               expandFunction={() => setTemplateOpen(!templateOpen)} open={templateOpen}/>
            <Collapse in={templateOpen} timeout="auto" unmountOnExit>
               <List component="div" disablePadding>
                  {
                     props.scriptList &&
                     props.scriptList.map(script => {
                        return (
                           <MenuItem
                              sx={{ pl: 4 }}
                              key={`sidebar${script.nickname}`}
                              text={script.nickname}
                              clickFunction={()=> gotoHref(`templates/edit/${script.idScript}`)}
                           />
                        );
                     })
                  }
               </List>
            </Collapse>
            {
               /*
               <MenuItem
                  text='Upcoming Features'
                  clickFunction={() => gotoHref('upcoming')}
                  selected={props.pagePath === 'upcoming'}
               />
               <MenuItem
               text='FAQ'
               clickFunction={() => gotoHref('faq')}
               selected={props.pagePath ==='faq'}
               />
                */
            }
         </div>
      )
   }
   return (
      <Drawer anchor="left" variant="persistent" open={props.drawerOpen}>
         <DrawerHeader>
            <IconButton onClick={toggleMenu}>
               <ChevronLeft/>
            </IconButton>
          </DrawerHeader>
         <List className="sidebar">
            {MenuList()}
         </List>
      </Drawer>
   )
}
