import React, {useState} from 'react';
import {CustomButtonList} from '../customButtonList.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import {Checkbox, FormControlLabel, Select, MenuItem, Stack} from '@mui/material';

function WebcamSelector(){
   const [devices, setDevices] = useState([]);
   const [selectedCamera, setSelectedCamera] = useState();
   const [doNotSendSessionData, setDoNotSendSessionData ]
      = useState(localStorage.getItem('doNotSendSessionData') || false);

   if(devices.length === 0){
      if(navigator.mediaDevices?.enumerateDevices){
         navigator.mediaDevices.enumerateDevices().then(devices => {
            setDevices(devices);
         });
      }
   }

   let handleValueChange = (e) => {
      setSelectedCamera(e.target.value);
      localStorage.setItem('selectedWebcam', e.target.value);
   };
   let updateDoNotSendSessionData = (e) => {
      setDoNotSendSessionData(e.target.checked);
      localStorage.setItem('doNotSendSessionData', e.target.checked);
   };

   return (
      <Stack direction={'column'} alignItems={'center'} spacing={1} sx={{width:300,zIndex:10, background:'gray'}} >
         <Select  value={selectedCamera}  fullWidth color='primary'
            renderValue={(val) => {
               let sel = devices.find(d => d.deviceId === val);
               return  sel.label;
            }}
            onChange={(e) => handleValueChange(e)}
            onClick={(e)=> e.stopPropagation()}
         >
            <MenuItem value={''}>Default</MenuItem>
            {
               devices.map(x => {
                  if(x.kind === 'videoinput')
                     return (<MenuItem key={x.deviceId} value={x.deviceId}>{x.label}</MenuItem>);
               })
            }
         </Select>

         <FormControlLabel
            onClick={(e)=> e.stopPropagation()}
            control={
               <Checkbox
                  label="doNotSendSessionData"
                  checked={doNotSendSessionData}
                  onChange={(e) =>
                     updateDoNotSendSessionData(e)
                  }
               />
            }
            label="doNotSendSessionData"
         />
      </Stack>

   );
}


/**
 *
 * @param {WelcomeScreenScript} props
 * @returns {JSX.Element}
 * @constructor
 */
export function WelcomeScreen(props){
   const goToNextScreen = () => {

      props?.customFunctions?.onWelcomeScreenButtonClicked?.();

      if(props.fathomConfig?.startId)
         window?.fathom?.trackGoal?.(props.fathomConfig.startId, 1);
      props.goToNextScreen();
   };

   return (
      <div className="welcome-screen screen" onClick={() => goToNextScreen()}>
         {
            !navigator.onLine &&
            <img src={'./no_wifi_black.png'} id={'offlineIndicator'}>

            </img>
         }
         {
            props.debugMode && <WebcamSelector />
         }
         {
            props.adminModeScreenIndex &&
            <img
               className={'admin-mode-button'}
               src={'./Button_Config.png'}
               onClick={(e) => {
                  e.stopPropagation();
                  props.goToScreen(props.adminModeScreenIndex);
               }}
            >
            </img>
         }
         <div className={'welcome-screen-content'}>
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
            />
            <div id={'welcomeScreenButtonRow'}>
               <NavigationButtons
                  {...props}
                  goToNextScreen={() => {
                     goToNextScreen();
                  }}
               />
               <CustomButtonList customButtons={props.customButtons} />
            </div>
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}
