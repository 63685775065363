import 'regenerator-runtime/runtime.js';
import React from 'react';
import 'toastify-js/src/toastify.css';
import {TopBar} from '../react/topBar';
import {PersistentDrawer} from '../react/sideBar';

export class PageTemplate extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         drawerOpen:false,
      }
   }

   toggleMenu (){
      this.setState({drawerOpen:!this.state.drawerOpen})
   }

   render() {
      return(
         <div>
            <TopBar
               user={this.props.user}
               pageTitle={this.props.pageTitle}
               drawerOpen={this.state.drawerOpen}
               toggleMenu={()=>{this.toggleMenu()}}
            />
            <PersistentDrawer
               pagePath={this.props.pagePath}
               drawerOpen={this.state.drawerOpen}
               scriptList={this.props.scriptList}
               toggleMenu={()=>{this.toggleMenu()}}
            />
            {
               this.props.children
            }
         </div>
      )

   }
}
