import 'regenerator-runtime/runtime.js';
import ReactDOM from 'react-dom';
import React from 'react';
import { Box } from '@mui/material';
import {MediaDeliveryGraph} from '../react/graph';
import * as AjaxFunctions from '../editorAjaxFunctions'
import {PageTemplate} from '../userConfig/pageTemplate';

class Dashboard extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         scripts : [],
         kioskList : [],
         retrievedScript:false
      }
   }
   async componentDidMount() {
      let scripts, kioskList;

      if(window.localStorage.getItem('isAdmin') === 'true') {
         scripts = await AjaxFunctions.getScriptsByUser(window.localStorage.getItem('idUser'));
         kioskList = await AjaxFunctions.getKioskInfoListByUser(window.localStorage.getItem('idUser'));
      }
      else {
         scripts = await AjaxFunctions.getScriptsForOrg();
         kioskList = await AjaxFunctions.getKioskInfoListForUser();
      }

      scripts.sort((a,b) => (a.nickname.toUpperCase() > b.nickname.toUpperCase()) ? 1 : -1 );
      kioskList.sort((a,b) => ((a.nameByBv||a.nickname||a.idKiosk).toUpperCase() > (b.nameByBv||b.nickname||b.idKiosk).toUpperCase()) ? 1 : -1);
      this.setState({scripts, kioskList, retrievedScript: true});
   }
   render() {
      return(
         <div>
            <PageTemplate
               user={window.BV.user}
               scriptList={this.state.scripts}
               pagePath={'dashboard'}
               pageTitle={'Analytics'}
               children={
                  <Box className='main-view'>
                     <div className={'dashboard'}>
                        <div className={'dashboard-graph'}>
                           {
                              this.state.retrievedScript &&
                              <MediaDeliveryGraph
                                 kioskList={this.state.kioskList}
                                 scriptList={this.state.scripts}
                                 displayWebAsKiosk={true}
                              />
                           }
                        </div>
                     </div>
                  </Box>
               }
            />
         </div>
      )

   }
}


export async function startConfig() {
   ReactDOM.render(
      <React.StrictMode>
         <Dashboard/>
      </React.StrictMode>,
      document.getElementById('bvReactRoot')
   );
}
