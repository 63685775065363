import React, {useEffect} from 'react';
import {Background} from '../background.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import * as SystemInfo from '../../systeminfo.js';
import {CustomElementsList} from '../customElementsList.jsx';
import {resolveUrl} from '../../utils.js';
let videoType = (SystemInfo.isIOS() || SystemInfo.getBrowserName() === 'Safari') ? 'mov' : 'webm';

export function getSelectionBase(val){
   if(!val || typeof val !== 'string'){
      return val;
   }

   let splitVal = val.split('.');
   if(splitVal.length > 1){
      let lastPart = splitVal[splitVal.length - 1];
      if(lastPart === 'webm' || lastPart === 'png' || lastPart === 'mov'){
         splitVal.pop();
         lastPart = splitVal[splitVal.length - 1];
      }
      if( Number.isInteger(parseInt(lastPart[lastPart.length - 1])) ){
         splitVal[splitVal.length - 1] = lastPart.substring(0, lastPart.length - 1);
      }
      val = splitVal.join('.');
   }
   else if( Number.isInteger(parseInt(val[val.length - 1])) ){
      val = val.substring(0, val.length - 1);
   }

   return val;
}

export function getSelectionCountBucket(selectionCount){
   let buckets = [0, 2, 4, 6, 9, 12, 15, 18, 20, 10000];
   for(let i = buckets.length - 1; i >=0; i--){
      if(selectionCount > buckets[i]){
         return buckets[i + 1];
      }
   }
   return 0;
}

export function Option(props){
   let classNames = 'photobooth-option';

   let foundBucket = getSelectionCountBucket(props.optionCount);

   classNames += ` photobooth-option-${foundBucket}orless`;

   let previewImageSrc = props.previewImageSrc;

   if(props.selected){
      classNames += ' selected';
      if(previewImageSrc && props.selectedPreviewImageSrc){
         previewImageSrc = props.selectedPreviewImageSrc;
      }
   }
   if(props.classNames){
      classNames += ' ' + props.classNames;
   }

   if(props?.previewImageSrc){
      return (
         <img src={resolveUrl(previewImageSrc)} className={classNames} onClick={() => props.onClick()} />
      );
   }

   return (
      <div className={classNames} onClick={() => props.onClick()}>
         {props.value}
      </div>
   );
}

export function processSelection(curSelections, selectionOption, targetElementsLength){
   let newSelections = curSelections.slice();

   let foundIndex = newSelections.findIndex(x => x.uuid === selectionOption.uuid);
   if(foundIndex >= 0) {
      //if you can only pick one, don't bother removing the only remaining selection;
      if(targetElementsLength > 1) {
         newSelections = [...newSelections.slice(0, foundIndex), ...newSelections.slice(foundIndex + 1)];
      }
   }
   else {
      if(newSelections.length < targetElementsLength){
         newSelections.push(selectionOption);
      }
      else {
         newSelections = newSelections.slice(1, newSelections.length);
         newSelections.push(selectionOption);
      }

      if(selectionOption.onSelectVideoSrc){

         function getSrcForSelection(sel){
            let src = sel.onSelectVideoSrc;
            if(sel.appendVideoExtensionToOnSelectSrc) {
               //src += '.' + videoType;

            }

            let extension = src.split('.').pop();
            if(extension !== videoType){
               //this means there is no extension, so assume it was left off
               if(extension.length > 4){
                  src += '.' + videoType;
               }
               else {
                  src = src.split('.').slice(0, -1).concat(videoType).join('.');
               }
            }

            return resolveUrl(src);
         }

         if(newSelections.length > 1){
            let otherSelection = null;
            if(newSelections[0].uuid === selectionOption.uuid){
               otherSelection = newSelections[1];
            }
            else {
               otherSelection = newSelections[0];
            }

            let videoElement1 =  document.getElementById('userSelectionScreenVideo');
            let videoElement2 =  document.getElementById('userSelectionScreenVideo2');

            //if unset, or if the other element matches the existing option.
            if(!videoElement2.booth_uuid || videoElement1.booth_uuid === otherSelection.uuid){
               videoElement2.src = getSrcForSelection(selectionOption);
               videoElement2.booth_uuid = selectionOption.uuid;
               videoElement2.play();
               videoElement2.style.display = '';
            }
            else if(!videoElement1.booth_uuid || videoElement2.booth_uuid === otherSelection.uuid){
               videoElement1.src = getSrcForSelection(selectionOption);
               videoElement1.booth_uuid = selectionOption.uuid;
               videoElement1.play();
               videoElement1.style.display = '';
            }

         }
         else {
            let videoElement = document.getElementById('userSelectionScreenVideo');
            videoElement.src = getSrcForSelection(selectionOption);
            videoElement.booth_uuid = selectionOption.uuid;
            videoElement.play();
            videoElement.style.display = '';
         }
      }
   }

   return newSelections;
}

export function mapValues(values){
   return values.map(y => {
      //Ff items in the same selection group have the same numRandItems, make sure each one gets the same number.
      //This is for things like having a video and having the player still associated with it always matching
      let randomNumbersMap = {};
      return y.map(z => {
         let val = z.value;
         if(z.numRandomItems){
            if(!randomNumbersMap[z.numRandomItems]){
               let storedValue = localStorage.getItem('debugVideoSelect');
               if(storedValue){
                  randomNumbersMap[z.numRandomItems] = Math.min(z.numRandomItems, parseInt(storedValue));
               }
               else {
                  let min = 1;
                  let max = z.numRandomItems;
                  randomNumbersMap[z.numRandomItems] = Math.floor(Math.random() * (max - min + 1)) + min;
               }
            }

            let splitVal = val.split('.');
            //check if there is a file extension, and insert the number before it if so.
            if(splitVal.length > 1 && splitVal[splitVal.length - 1].length < 5){
               splitVal[splitVal.length - 2] = splitVal[splitVal.length - 2] + randomNumbersMap[z.numRandomItems];
               val = splitVal.join('.');
            }
            else {
               val += randomNumbersMap[z.numRandomItems];
            }

         }
         if(z.appendVideoExtensionToValue){
            //val += videoType;
         }
         return val;
      });
   });
};

/**
 *
 * @param {UserSelectionScreen} props
 * @returns {JSX.Element}
 * @constructor
 */
export function UserSelectionScreen(props){
   let canContinue = false;

   //empty string is ok, undefined is not
   if(props.userVariables[props?.targetElements?.[0]?.items?.[0]?.idElement] !== undefined){
      canContinue = true;
   }

   let visibleOptions = props.options.filter(x => !x.hidden && !x.isArchived);

   useEffect(async () => {
      if(!props.isInEditor && visibleOptions.length === 1) {
         await autoSelect();
      }

   }, [props.options]);

   let onSelect = async (selectionIndex) => {
      let selectionOption = props.options[selectionIndex];
      if(selectionOption.disabled){
         return;
      }

      let curSelections = props.userSelectionsByScreen[props.uuid] || [];

      let newSelections = processSelection(curSelections, selectionOption, props.targetElements.length);

      props.setUserSelection(props.uuid, newSelections);


      //map the values from newSelection onto the variables

      let newVals = {};
      for(let i = 0; i < props.targetElements.length; i++){
         let targetElementGroup = props.targetElements[i].items;
         let values = mapValues(newSelections[i]?.values || []);
         for(let j = 0; j < targetElementGroup.length; j++){
            newVals[targetElementGroup[j].idElement] = values?.[i]?.[j];
            //await props.setUserVariable(targetElementGroup[j].idElement, values?.[i]?.[j]);
         }
      }
      await props.setUserVariables(newVals);

   };

   let autoSelect = async () => {
      try{
         // select option
         let selections =  [visibleOptions[0]];
         props.setUserSelection(props.uuid, selections);
         // go to next screen
         let newVals = {};
         for(let i = 0; i < props.targetElements.length; i++){
            let targetElementGroup = props.targetElements[i].items;
            let values = mapValues(selections[i]?.values || []);
            for(let j = 0; j < targetElementGroup.length; j++){
               newVals[targetElementGroup[j].idElement] = values?.[i]?.[j];
            }
         }
         await props.setUserVariables(newVals);
         props?.customFunctions?.onUserSelectSubmit?.(props.userVariables);
         await props.goToNextScreen();
      }
      catch (err) {
         console.error(err);
      }
   };

   if(!props.isInEditor && visibleOptions.length === 1) {
      return (<div/>);
   }
   else {
      let screenStyles = props.styles;
      let optionParentStyles = 'user-selection-screen-options';

      let foundBucket = getSelectionCountBucket(visibleOptions.length);
      optionParentStyles += ` user-selection-screen-options-${foundBucket}orless`;
      screenStyles += ` user-selection-screen-options-${foundBucket}orless`;

      return (
         <div className={'user-selection-screen screen ' + screenStyles}>
            <div className={'user-selection-screen-video-container'} >
               <video id={'userSelectionScreenVideo'} src={''} style={{display: 'none'}} playsInline muted> </video>
               <video id={'userSelectionScreenVideo2'} src={''} style={{display: 'none'}} playsInline muted> </video>
            </div>
            <div className={'user-selection-screen-content'} >
               <CustomElementsList
                  userData={props.userData}
                  customElements={props.elements}
                  userVariables={props.userVariables}
               />
               <div className={optionParentStyles}>
                  {
                     props.options.map((x, ind) => {
                        if(x.hidden || x.isArchived)
                           return (
                              <div key={ind}/>
                           );

                        let values = x.values.map(y => {
                           return y.map(z => {
                              let val = z.value;
                              if(z.appendVideoExtensionToValue){
                                 //val += videoType;
                              }
                              return val;
                           });
                        });

                        let selected = false;
                        for(let i = 0; i < props.targetElements.length; i++){
                           let group = props.targetElements[i];
                           let curVariables = {...props?.userVariables};
                           Object.keys(curVariables).forEach(key => {
                              curVariables[key] = getSelectionBase(curVariables[key]);
                           });
                           let savedValuesArray = group.items.map(x => curVariables[x.idElement]);
                           let groupVariables = (values?.[i] || []).map(x => getSelectionBase(x));
                           if(savedValuesArray.length === groupVariables.length){
                              selected = true;
                              for(let i = 0; i < savedValuesArray.length; i++){
                                 if(savedValuesArray[i] !== groupVariables[i]){
                                    selected = false;
                                    break;
                                 }
                              }
                           }
                           if(selected){
                              break;
                           }
                        }

                        return (
                           <Option
                              key={ind}
                              onClick={async () => await onSelect(ind)}
                              selected={selected}
                              optionCount={visibleOptions.length}
                              {...x}
                           />
                        );
                     })
                  }
               </div>
               <NavigationButtons
                  {...props}
                  goToNextScreen={() => {
                     props?.customFunctions?.onUserSelectSubmit?.(props.userVariables);
                     props.goToNextScreen();
                  }}
                  disableNextButton={!canContinue}
               />
            </div>
            <Background userData={props.userData} elements={props.backgroundElements} />
         </div>
      );
   }
}