import React, {useEffect} from 'react';
import {Background} from '../background.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';
import * as Utils from '../../utils.js';
import * as QRCode from 'qrcode';

export function QRConfirmScreen(props){


   useEffect(async () => {

      if(props.videoSrc && props.videoSrc.src.size > 0){
         await props.setUserVariables({qrSent:true});

         let response = null;

         let origin = window.location.origin;
         if(origin === 'file://'){
            origin = 'https://arfanfotos.com';
         }

         let uploadFileUrl = origin + '/getMedia/' + window.BV.photoboothScript.idScript + '/';
         let uploadFileName = BVExtras.generateUploadFileName();
         uploadFileUrl += uploadFileName;

         if(props.videoSrc.type === 'video/webm'){
            response = await BVExtras.uploadVideoWithFilename(
               props.videoSrc.src, uploadFileName
            );
         }
         else {
            response = await BVExtras.uploadImageWithFilename(
               props.videoSrc.src, uploadFileName
            );
         }

         if(props.requireContactVerification){
            uploadFileUrl = uploadFileUrl.replace('getMedia', 'contactInfo');
         }
         else {
            if(props.videoSrc.type !== 'video/webm')
               uploadFileUrl += '.png';
         }

         let canvas = document.getElementById('qrConfirmScreenCanvas');
         let qrOptions = {
            color: {
               dark: props.qrDarkColor || '#009FDBFF',
               light: props.qrLightColor || '#FFFFFFFF'
            }
         };
         QRCode.toCanvas(canvas, uploadFileUrl, qrOptions);
         console.log('QR generated: ' + uploadFileUrl);


         //let uploadFileName = response.url;


         if(props.formstackConfig?.genericConfig) {
            let isRequirementFulfilled = true;
            if(props.formstackConfig.genericConfig.requirementVariable){
               isRequirementFulfilled = props.userVariables[props.formstackConfig.genericConfig.requirementVariable];
            }
            if(isRequirementFulfilled) {
               let populatedFields = Utils.populateFormstackData(
                  props.formstackConfig.genericConfig.fields,
                  props.userVariables
               );

               BVExtras.postFormstack(
                  props.formstackConfig.apiToken,
                  props.formstackConfig.genericConfig.idForm,
                  populatedFields
               );
            }
         }
         if(props.fathomConfig?.qrSendConfirmedId)
            window?.fathom?.trackGoal?.(props.fathomConfig.qrSendConfirmedId, 1);


      }


   }, []);

   return (
      <div className={'qr-confirm-screen screen ' + props.styles}>
         <div className={'qr-confirm-screen-content'}>
            <div className={'qr-confirm-screen-header-text'}>
               {props.headerText}
            </div>
            <div className={'qr-confirm-screen-detail-text'}>
               {props.detailText}
            </div>
            <canvas id={'qrConfirmScreenCanvas'}  />
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <NavigationButtons
               {...props}
            />
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}