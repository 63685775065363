import React, {useState} from 'react';
import {TextFormFieldType} from '../../shared/dataTypes.js';

function KeyboardContainerABC(props){
   return (
      <div className="osk-page">
         <div className="row">
            <div onClick={() => props.letterClick('1')} >1</div>
            <div onClick={() => props.letterClick('2')} >2</div>
            <div onClick={() => props.letterClick('3')} >3</div>
            <div onClick={() => props.letterClick('4')} >4</div>
            <div onClick={() => props.letterClick('5')} >5</div>
            <div onClick={() => props.letterClick('6')} >6</div>
            <div onClick={() => props.letterClick('7')} >7</div>
            <div onClick={() => props.letterClick('8')} >8</div>
            <div onClick={() => props.letterClick('9')} >9</div>
            <div onClick={() => props.letterClick('0')} >0</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('Q')} >Q</div>
            <div onClick={() => props.letterClick('W')} >W</div>
            <div onClick={() => props.letterClick('E')} >E</div>
            <div onClick={() => props.letterClick('R')} >R</div>
            <div onClick={() => props.letterClick('T')} >T</div>
            <div onClick={() => props.letterClick('Y')} >Y</div>
            <div onClick={() => props.letterClick('U')} >U</div>
            <div onClick={() => props.letterClick('I')} >I</div>
            <div onClick={() => props.letterClick('O')} >O</div>
            <div onClick={() => props.letterClick('P')} >P</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('A')} >A</div>
            <div onClick={() => props.letterClick('S')} >S</div>
            <div onClick={() => props.letterClick('D')} >D</div>
            <div onClick={() => props.letterClick('F')} >F</div>
            <div onClick={() => props.letterClick('G')} >G</div>
            <div onClick={() => props.letterClick('H')} >H</div>
            <div onClick={() => props.letterClick('J')} >J</div>
            <div onClick={() => props.letterClick('K')} >K</div>
            <div onClick={() => props.letterClick('L')} >L</div>
            <div onClick={() => props.symbolSwitch()} className={'long-text'} >SYMBOLS</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('Z')} >Z</div>
            <div onClick={() => props.letterClick('X')} >X</div>
            <div onClick={() => props.letterClick('C')} >C</div>
            <div onClick={() => props.letterClick('V')} >V</div>
            <div onClick={() => props.letterClick('B')} >B</div>
            <div onClick={() => props.letterClick('N')} >N</div>
            <div onClick={() => props.letterClick('M')} >M</div>
            {
               props.type !== TextFormFieldType.TextNoDomain &&
               <React.Fragment>
                  <div onClick={() => props.letterClick('.COM')} className={'medium-text'}>.COM</div>
                  <div onClick={() => props.letterClick('.NET')} className={'medium-text'}>.NET</div>
               </React.Fragment>
            }
            <div onClick={() => props.deleteLetter()} className={'medium-text'} >DEL</div>

         </div>
         {
            props.type === TextFormFieldType.Email &&
            <div className="row">
               <div onClick={() => props.letterClick('@')} >@</div>
               <div onClick={() => props.letterClick('.')} >.</div>
               <div onClick={() => props.letterClick('@gmail.com')} className={'domain-text'}>@GMAIL.COM</div>
               <div onClick={() => props.letterClick('@outlook.com')} className={'domain-text'}>@OUTLOOK.COM</div>
               <div onClick={() => props.letterClick('@yahoo.com')} className={'domain-text'}>@YAHOO.COM</div>
            </div>
         }
         {
            props.type === TextFormFieldType.TextNoDomain &&
            <div className="row">
               <div onClick={() => props.letterClick(' ')} className={'domain-text'} >SPACE</div>
            </div>
         }
         {
            props.type !== TextFormFieldType.Email && props.type !== TextFormFieldType.TextNoDomain &&
            <div className="row">
               <div onClick={() => props.letterClick('@')} >@</div>
               <div onClick={() => props.letterClick('.')} >.</div>
               <div onClick={() => props.letterClick(' ')} className={'domain-text'} >SPACE</div>
            </div>
         }

      </div>
   );
}

function KeyboardContainerSymbols(props){
   return (
      <div className="osk-page" >
         <div className="row">
            <div onClick={() => props.letterClick('~')} >~</div>
            <div onClick={() => props.letterClick('!')} >!</div>
            <div onClick={() => props.letterClick('@')} >@</div>
            <div onClick={() => props.letterClick('#')} >#</div>
            <div onClick={() => props.letterClick('$')} >$</div>
            <div onClick={() => props.letterClick('%')} >%</div>
            <div onClick={() => props.letterClick('^')} >^</div>
            <div onClick={() => props.deleteLetter()} className={'medium-text'} >DEL</div>
         </div>
         <div className="row" >
            <div onClick={() => props.letterClick('&')} >&</div>
            <div onClick={() => props.letterClick('<')} >&lt;</div>
            <div onClick={() => props.letterClick('>')} >&gt;</div>
            <div onClick={() => props.letterClick(',')} >,</div>
            <div onClick={() => props.letterClick('.')} >.</div>
            <div onClick={() => props.letterClick('?')} >?</div>
            <div onClick={() => props.letterClick(';')} >;</div>
            <div onClick={() => props.letterClick(':')} >:</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('\'')} >&apos;</div>
            <div onClick={() => props.letterClick('"')} >&quot;</div>
            <div onClick={() => props.letterClick('=')} >=</div>
            <div onClick={() => props.letterClick('+')} >+</div>
            <div onClick={() => props.letterClick('-')} >-</div>
            <div onClick={() => props.letterClick('_')} >_</div>
            <div onClick={() => props.letterClick('*')} >*</div>
            <div onClick={() => props.symbolSwitch()} className={'long-text'}>LETTERS</div>
         </div>
      </div>
   );
}

function NormalPage(props){
   const [page, setPage] = useState(0);

   return (
      <React.Fragment>
         {
            page === 0 &&
            <KeyboardContainerABC
               letterClick={(string) => props.onLetterClick(string)}
               symbolSwitch={() => setPage(1)}
               deleteLetter={() => props.deleteLetter()}
               type={props.type}
            />
         }
         {
            page === 1 &&
            <KeyboardContainerSymbols
               letterClick={(string) => props.onLetterClick(string)}
               symbolSwitch={() => setPage(0)}
               deleteLetter={() => props.deleteLetter()}
            />
         }
      </React.Fragment>

   );
}

function NumberPage(props){
   return (
      <div className="osk-page">
         <div className="row">
            <div onClick={() => props.letterClick('1')} >1</div>
            <div onClick={() => props.letterClick('2')} >2</div>
            <div onClick={() => props.letterClick('3')} >3</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('4')} >4</div>
            <div onClick={() => props.letterClick('5')} >5</div>
            <div onClick={() => props.letterClick('6')} >6</div>
         </div>
         <div className="row">
            <div onClick={() => props.letterClick('7')} >7</div>
            <div onClick={() => props.letterClick('8')} >8</div>
            <div onClick={() => props.letterClick('9')} >9</div>
         </div>
         <div className="row number-last-row">
            <div onClick={() => props.letterClick('')} className={'blank-key'} > </div>
            <div onClick={() => props.letterClick('0')} >0</div>
            <div onClick={() => props.deleteLetter()} className={'medium-text'} >DEL</div>
         </div>
      </div>
   );
}

export function OnScreenKeyboard(props){

   if(
      props.type === TextFormFieldType.Phone
      || props.type === TextFormFieldType.ZipCode
      || props.type === TextFormFieldType.Number
   ){
      return <NumberPage
         letterClick={(string) => props.onLetterClick(string)}
         deleteLetter={() => props.deleteLetter()}
      />;
   }
   else {
      return <NormalPage {...props} />;
   }
}