import React, {useEffect} from 'react';
import {Background} from '../background.jsx';
import {CustomElementsList} from '../customElementsList.jsx';
import {NavigationButtons} from '../navigationButtons.jsx';

/**
 *
 * @param {BlankScreenScript} props
 * @returns {JSX.Element}
 * @constructor
 */
export function BlankScreen(props){
   useEffect(() => {
      props?.customFunctions?.onBlankScreenMount?.(props);

      return () => {
         props?.customFunctions?.onBlankScreenUnmount?.(props);
      };
   });
   console.debug(props.userData);
   return (
      <div className={'blank-screen screen ' + props.styles}>
         <div className={'blank-screen-content'}>
            <CustomElementsList
               userData={props.userData}
               customElements={props.elements}
               userVariables={props.userVariables}
            />
            <NavigationButtons
               {...props}
            />
         </div>
         <Background userData={props.userData} elements={props.backgroundElements} />
      </div>
   );
}