import React, { useState } from 'react';
import { Paper, TextField, Typography} from '@mui/material';
import { useEffect } from 'react';
import { Stack } from '@mui/system';

export function DateRange (props) {

   const [startDate, setStart ] = useState(props.defaultStartDate||'');
   const [endDate, setEnd ] = useState(props.defaultEndDate||'');

   useEffect(()=>{
      setEnd(props.defaultEndDate);
      setStart(props.defaultStartDate);
   }, [props.defaultEndDate, props.defaultStartDate])

   const setStartDate = (value) => {
      //example value: 2022-10-28
      setStart(value);
      if(props.setStartDate) props.setStartDate(value);
   }
   const setEndDate = (value) => {
      setEnd(value);
      if(props.setEndDate) props.setEndDate(value);
   }
   return (
      <div style={props.style||{}} className='infoPanelRow'>
         <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <h6 style={{margin:'auto 0'}}>DATE RANGE</h6>
            <Typography variant='caption'>Current Timezone: {props.currentTimezone}</Typography>
         </Stack>
         <Paper elevation={0} className={'border-box date-range-box'}>
            <TextField value={startDate}     
               label={'Start Date'}
               variant={'outlined'}
               type={'date'}
               onChange={(e) => setStartDate(e.target.value)}
               InputLabelProps={{ shrink: true }}
               InputProps={{inputProps:{
                  min: props.minDate,
                  max : endDate
               }}}/>
            <TextField value={endDate}
               style={{marginLeft:'0.5em'}}
               label={'End Date'}
               variant={'outlined'}
               type={'date'}
               onChange={(e) => setEndDate(e.target.value)}
               InputLabelProps={{ shrink: true }}
               InputProps={{inputProps:{
                  min : startDate,
                  max: props.todayDate
               }}}/>
         </Paper>
      </div>
   )
}
