import React, {useEffect, useState} from 'react';
import * as SystemInfo from '../systeminfo.js';
import {resolveUrl} from '../utils.js';
import * as Ajax from '../ajax.js';

let videoType = (SystemInfo.isIOS() || SystemInfo.getBrowserName() === 'Safari') ? '.mov' : '.webm';

function DeleteButton(props){

   let onClick = () => {
      props.onClick();
   };

   if(props.buttonUrl){
      return (
         <img
            src={resolveUrl(props.buttonUrl)}
            className={'custom-element-get-request-delete-button'}
            onClick={() => onClick(props.el)}
         />
      );
   }

   return (
      <button
         className={'custom-element-get-request-delete-button'}
         onClick={() => onClick(props.el)}
      >
         {props.buttonText}
      </button>
   );

}

function GetRequestList(props){

   const [optionsList, setOptionsList] = useState([]);
   const [selectedOption, setSelectedOption] = useState([]);

   let getFunction = async () => {
      try {
         let results = await Ajax.get(resolveUrl(props.getRequestUrl));
         results = results.data;
         setOptionsList(results);
         //window.BV.customFunctions?.[el.onClickCustomFunction]?.(ret);
      }
      catch (e) {
         console.warn(e);
      }
   };

   let onSelect = async (el) => {
      await window.BV.app.setUserVariables(el);
      setSelectedOption(el[props.valueToDisplay]);
   };

   useEffect(async () => {
      await getFunction();
   }, []);

   let getButton =
      <button
         className={'custom-element-get-request-list-button'}
         onClick={() => getFunction()}
      >
         {props.reloadButtonText}
      </button>;

   if(props.reloadButtonUrl){
      getButton =
         <img
            src={resolveUrl(props.reloadButtonUrl)}
            className={'custom-element-get-request-list-button'}
            onClick={() => getFunction()}
         />;
   }

   let deleteItem = async (item) => {
      await window.BV.customFunctions?.[props.deleteItemFunction]?.(item);
      await getFunction();
   };

   return (
      <div
         id={props.id}
         className={props.class +' custom-element-get-request-list-container'}
      >
         {getButton}
         <div className={'custom-element-get-request-list-container-items'}>
            {
               optionsList.map(el => {
                  let isSelectedClass = selectedOption === el[props.valueToDisplay] ? 'selected' : '';
                  return(
                     <div
                        key={el[props.valueToDisplay]}
                        className={'custom-element-get-request-list-item ' + isSelectedClass}
                     >
                        <button
                           onClick={() => onSelect(el)}
                           className={isSelectedClass}
                        >
                           {el[props.valueToDisplay]}
                        </button>
                        {
                           props.deleteItemFunction &&
                           <DeleteButton
                              buttonText={props.deleteButtonText}
                              buttonUrl={props.deleteButtonUrl}
                              onClick={() => deleteItem(el)}
                           />
                        }
                        
                     </div>
                  );
               })
            }
         </div>
      </div>
   );
}

export function CustomElementsList(props){
   return (
      <React.Fragment>
         {
            props.customElements?.map((el, ind) => {

               let onClickFunction = () => {
                  if(el.onClickGoToPageNumber !== undefined){
                     window.BV.app.goToScreen(el.onClickGoToPageNumber);
                  }
                  window.BV.customFunctions?.[el.onClickCustomFunction]?.();
               };
               
               let commonProps = {
                  id: el.id,
                  className: el.class,
                  key: el.id || el.key || ind,
                  onClick: () => onClickFunction()
               };

               let text = el.innerText;
               if(el.variableName){
                  text = props?.userVariables?.[el.variableName] ?? el.variableName;
               }


               if(el.type === 'img'){

                  let configEditorVariable = null;
                  
                  let imgSrc = el.src;

                  if(el.configEditorVariableName) {
                     let userData = null;

                     if(props.userData && props.userData.hasOwnProperty(el.configEditorVariableName)){
                        userData = props.userData;
                     }
                     else if(window.BV.userData && window.BV.userData.hasOwnProperty(el.configEditorVariableName)) {
                        userData = window.BV.userData;
                     }

                     if( userData) {
                        configEditorVariable = userData[el.configEditorVariableName].find(x =>
                           x.selectedBy.includes(window.BV.photoboothScript.idScript)
                        );
                        if(configEditorVariable && configEditorVariable.hasOwnProperty('src'))
                        {
                           if(configEditorVariable.hasOwnProperty('baseUrl') )
                              imgSrc = configEditorVariable.baseUrl + configEditorVariable.src;
                           else
                              imgSrc = configEditorVariable.src;
                        }
                     }

                  }
                  return (
                     <img
                        {...commonProps}
                        src={resolveUrl(imgSrc )}
                        aria-label={el.ariaLabel}
                     />
                  );
               }
               if(el.type === 'video'){
                  let src = el.src;
                  if(el.generateSrcExtension){
                     src += videoType;
                  }
                  return (
                     <video
                        {...commonProps}
                        src={resolveUrl(src)}
                        aria-label={el.ariaLabel}
                        autoPlay
                        playsInline
                        muted
                        loop
                     />
                  );
               }
               if(el.type === 'phone'){

                  if(text?.length === 10){
                     text = `(${text.substring(0,3)}) ${text.substring(3, 6)}-${text.substring(6, 10)}`;
                  }

                  return (
                     <div
                        {...commonProps}
                     >
                        {text}
                     </div>
                  );
               }
               if(el.type === 'getRequest'){

                  let getFunction = async () => {
                     try {
                        let results = await Ajax.get(resolveUrl(el.getRequestUrl));
                        let ret = JSON.parse(results.data);
                        await window.BV.app.setUserVariables(ret);
                        window.BV.customFunctions?.[el.onClickCustomFunction]?.(ret);
                     }
                     catch (e) {
                        console.warn(e);
                     }
                  };

                  let getButton =
                     <button
                        className={'custom-element-get-request-button'}
                        onClick={() => getFunction()}
                     >
                        {el.buttonText}
                     </button>;

                  if(el.buttonUrl){
                     getButton =
                        <img
                           src={resolveUrl(el.buttonUrl)}
                           className={'custom-element-get-request-button'}
                           onClick={() => getFunction()}
                        />;
                  }

                  return (
                     <div
                        id={el.id}
                        className={el.class +' custom-element-get-request-container'}
                        key={el.id || el.key || ind}

                     >
                        {getButton}
                     </div>
                  );
               }
               if(el.type === 'getRequestList'){
                  return (
                     <GetRequestList {...el} ind={ind} key={el.id || el.key || ind} />
                  );
               }
               return (
                  <div
                     {...commonProps}
                     key={el.id || el.key || ind}
                  >
                     {text}
                  </div>
               );
            })
         }
      </React.Fragment>
   );
}