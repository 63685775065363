import React from 'react';

export function ReactProgressRing(props){
   let strokeWidth = 8;
   let radius = Math.floor((props.width - strokeWidth)/2);
   let circumference = Math.round(2 * Math.PI * radius);
   let percentage = props.percentage || 0;
   let offset = circumference - percentage / 100 * circumference;

   return (
      <svg
         id={'reactProgressRing'}
         className={'progress-ring'}
         width={props.width}
         height={props.width}
         xmlnsXlink='http://www.w3.org/1999/xlink'
      >
         <circle
            className={'progress-ring-circle'}
            stroke={'white'}
            fill='transparent'
            strokeWidth={strokeWidth.toString()}
            r={radius.toString()}
            cx={'50%'}
            cy={'50%'}
            style={{strokeDasharray: `${circumference} ${circumference}`, strokeDashoffset: offset}}
         />
      </svg>
   );
}