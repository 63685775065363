import {IImageProcessorStage} from './IImageProcessorStage.js';
import {bootstrapCameraKit, createVideoSource, Transform2D, Injectable, remoteApiServicesFactory} from '@snap/camera-kit';
import { Push2Web } from '@snap/push2web';

const push2Web = new Push2Web();

const defaultApiToken = 'eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjg0OTQxMjQzLCJzdWIiOiJiMWVmYzQxYS1kM2Q3LTQ5ZTctYmMwZi02ZDRhYmFmYTZmZDR-U1RBR0lOR342MTgyYWI2Mi0wMmVlLTQ4N2QtODU3Ny0zNmFkZDU2OTVkMjYifQ.x_BP1lD2Vkqt5GRvFaj6QwJS11q5uuxC3bNeMdS325A';

export class LensIntegration extends  IImageProcessorStage{
   constructor(lensGroupId, lensId, useBackCamera = false, isPushToWeb, photoboothWebcam, apiToken) {
      super();

      this.lensCanvasElement = null;
      this.cameraKit = null;
      this.session = null;
      this.started = false;
      this.lensGroupId = lensGroupId;
      this.lensId = lensId;
      this.apiToken = apiToken || defaultApiToken;

      this.availableLenses = [];
      this.currentLensIndex = 0;
      this.useBackCamera = useBackCamera;
      this.isPushToWeb = isPushToWeb;

      this.intermediateCanvas = null;
      this.cameraDomElement = null;

      this.isLoading = false;

      this.photoboothWebcam = photoboothWebcam;
   }

   async loadNextLens(){
      this.currentLensIndex = (this.currentLensIndex + 1) % this.availableLenses.length;
      await this.session.applyLens(this.availableLenses[this.currentLensIndex]);
   }

   async loadPreviousLens(){
      this.currentLensIndex = (this.currentLensIndex + this.availableLenses.length - 1) % this.availableLenses.length;
      await this.session.applyLens(this.availableLenses[this.currentLensIndex]);
   }

   async loadLensById(idLens){
      this.isLoading = true;
      this.session.pause();
      this.clearIntermediateCanvas();

      this.currentLensIndex = this.availableLenses.findIndex(x => x.id === idLens);
      await this.session.applyLens(this.availableLenses[this.currentLensIndex]);
      this.session.play();


      this.isLoading = false;
   }

   async init(){
      if(!window.BV){
         window.BV = {};
      }
      if(window.BV.lensIntegration){
         console.warn('LensIntegration processing stage can only be used once');
         return;
      }
      window.BV.lensIntegration = this;

      const fanFotoService = {
         apiSpecId: "0f9968f7-579f-4fe9-8cb9-c1784e0c0f70",

         getRequestHandler(request) {
            let parameters = {};
            try {
               if(request?.parameters?.jsData){
                  parameters = JSON.parse(request.parameters.jsData);
               }
            }
            catch (e) {
               console.log('error parsing jsData');
            }
            console.log(request.endpointId);

            if(request.endpointId === 'onStart'){
               let res = {
                  showFPS: true
               };
               return (reply) => {
                  reply({
                     status: 'success',
                     metadata: {},
                     body: new TextEncoder().encode(JSON.stringify(res)),
                  });
               };
            }

            if(request.endpointId === 'onEnd'){
               let res = {

               };
               return (reply) => {
                  reply({
                     status: 'success',
                     metadata: {},
                     body: new TextEncoder().encode(JSON.stringify(res)),
                  });
               };
            }

            if(request.endpointId === 'setVariable'){
               let res = {

               };
               return (reply) => {
                  reply({
                     status: 'success',
                     metadata: {},
                     body: new TextEncoder().encode(JSON.stringify(res)),
                  });
               };
            }

            if(request.endpointId === 'callFunction'){
               let res = {

               };
               return (reply) => {
                  reply({
                     status: 'success',
                     metadata: {},
                     body: new TextEncoder().encode(JSON.stringify(res)),
                  });
               };
            }

            if(request.endpointId === 'js_send_data'){

               return (reply) => {
                  let res = '{"fact":"The biggest wildcat today is the Siberian Tiger","length":158}';
                  reply({
                     status: "success",
                     metadata: {},
                     body: new TextEncoder().encode(res),
                  });
               };
            }
            return (reply) => {
               let res = '{"fact":"The biggest wildcat today is the Siberian Tiger","length":158}';
               reply({
                  status: "success",
                  metadata: {},
                  body: new TextEncoder().encode(res),
               });
            };
         }
      };

      if(this.isPushToWeb){
         const extensions = (container) => container.provides(push2Web.extension);

         this.cameraKit = await bootstrapCameraKit({
            apiToken: this.apiToken
         }, extensions);
      }
      else {
         this.cameraKit = await bootstrapCameraKit({
            apiToken: this.apiToken
         }, (container) => {
            return container.provides(
               Injectable(
                  remoteApiServicesFactory.token,
                  [remoteApiServicesFactory.token],
                  (existing) => [...existing, fanFotoService]
               )
            );
         });
      }

      this.cameraKit.metrics.addEventListener('lensView', (event) => {
         console.log('lensViewEvent');
         console.log(event.detail);
      });

      this.lensCanvasElement = document.createElement('canvas');
      this.lensCanvasElement.setAttribute('id', 'lensCanvas');
      document.body.append(this.lensCanvasElement);

      this.session = await this.cameraKit.createSession({liveRenderTarget: this.lensCanvasElement});

      if(this.isPushToWeb){

         let accessToken = snap?.loginkit?.getSharedDataAccessToken?.();

         if(accessToken) {
            push2Web.events.addEventListener("lensReceived", (event) => {
               const {
                  id,
                  name,
                  iconUrl,
                  cameraFacingPreference, // "CAMERA_FACING_UNSET" | "CAMERA_FACING_FRONT" | "CAMERA_FACING_BACK"
                  lensCreator,
               } = event.detail;
               console.log(event.detail);
            });

            push2Web.events.addEventListener("error", (event) => {
               const errorDetails = event.detail;
               console.warn(errorDetails);
            });

            push2Web.subscribe(
               accessToken,
               this.session,
               this.cameraKit.lensRepository
            );
         }
         else{
            console.warn('no access token');
         }
      }

      else {
         const lens = await this.cameraKit.lensRepository.loadLens(this.lensId, this.lensGroupId);

         const {lenses} = await this.cameraKit.lensRepository.loadLensGroups([this.lensGroupId]);

         this.availableLenses = lenses;


         if (lens) {
            this.currentLensIndex = lenses.findIndex(x => x.id === lens.id);
         }

         await this.session.applyLens(lens, {
            testText: 'From JS'
         });
      }
   }

   async start(cameraDomElement){
      this.started = true;

      let {
         dWidth, dHeight,
      } = this.drawingOptions;
      this.intermediateCanvas = document.createElement('canvas');
      this.intermediateCanvas.width = dWidth;
      this.intermediateCanvas.height = dHeight;
      this.cameraDomElement = cameraDomElement;

      this.intermediateCanvas.setAttribute('id', 'lensIntermediateCanvas');
      document.body.append(this.intermediateCanvas);

      this.videoSource = createVideoSource(this.intermediateCanvas);
      let options = {};
      if(this.useBackCamera){
         options.cameraType = 'environment';
         console.debug('using back camera');
      }
      await this.session.setSource(this.videoSource, options);
      this.session.play();
   }

   async run(image, cameraDomElement){

      if(!this.started && cameraDomElement){
         await this.start(cameraDomElement);
      }

      this.drawToIntermediateCanvas(cameraDomElement);

      let {
         dWidth, dHeight,
      } = this.drawingOptions;

      for(let canvas of this.canvasElements) {
         let ctx = canvas.getContext('2d');
         ctx.drawImage(
            this.lensCanvasElement,
            0, 0, dWidth, dHeight,
            0, 0, dWidth, dHeight
         );
      }
   }

   clearIntermediateCanvas(){
      let {
         dx, dy, dWidth, dHeight,
      } = this.drawingOptions;

      let ctx = this.intermediateCanvas.getContext('2d');
      ctx.clearRect(0,0,dWidth,dHeight);

   }

   drawToIntermediateCanvas(cameraDomElement){
      let ctx = this.intermediateCanvas.getContext('2d');
      let canvasCtx = ctx;
      let {
         sx, sy, sWidth, sHeight,
         dx, dy, dWidth, dHeight,
         cameraRotation, cameraRotationXOffsetMagnitude, cameraRotationYOffsetMagnitude
      } = this.drawingOptions;

      canvasCtx.save();
      canvasCtx.clearRect(0, 0, dWidth, dHeight);
      if(cameraRotation){
         let rotateXOffset = dWidth * cameraRotationXOffsetMagnitude; //this.signCosCameraRotation;
         let rotateYOffset = dHeight * cameraRotationYOffsetMagnitude;// 0;
         canvasCtx.setTransform(Transform2D.MirrorY);
         canvasCtx.translate(cameraDomElement.videoHeight, 0);
         canvasCtx.scale(-1,1);
         canvasCtx.translate(rotateXOffset, rotateYOffset);
         canvasCtx.rotate(cameraRotation);

         ctx.drawImage(
            cameraDomElement,
            0, 0, cameraDomElement.videoWidth, cameraDomElement.videoHeight,
            dx, dy, cameraDomElement.videoWidth, cameraDomElement.videoHeight
         );
      }
      else {
         ctx.drawImage(
            cameraDomElement,
            sx, sy, sWidth, sHeight,
            dx, dy, dWidth, dHeight
         );
      }

      canvasCtx.restore();

   }

   async onExperienceStop() {
      await super.onExperienceStop();
      await this.session.removeLens();
      for(let i = 0; i < 3; i++) {
         await this.run(null, this.cameraDomElement);
      }
      this.session.pause();
   }

   async onExperienceStart() {
      await super.onExperienceStart();
      if(!this.session.playing.live){
         await this.session.applyLens(this.availableLenses[this.currentLensIndex]);
         this.session.play();
      }
   }


}