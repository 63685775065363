const baseURL = (typeof (window) !== 'undefined' ? window.location.origin : null);
import * as Ajax from './ajax.js';

export function sendSMSWithImage(phoneNumber, text, image, idScript) {
   return Ajax.upload(baseURL + '/integration/sendSMSWithImage', image,{ phoneNumber, text, idScript });
}

export function sendSMS(phoneNumber, text, idScript) {
   return Ajax.post(baseURL + '/integration/sendSMS', { phoneNumber, text, idScript });
}

export function sendEmailWithImage(email, image, idScript, additionalData) {
   return Ajax.upload(baseURL + '/integration/sendEmailWithImage', image, { email, idScript, additionalData });
}

export function sendEmail(email, idScript, templateData) {
   return Ajax.post(baseURL + '/integration/sendEmail', { email, idScript, templateData});
}

export function postFormstack(token, idForm, fields){
   return Ajax.post(baseURL + '/integration/postFormstackData', {token, idForm, fields});
}

export function regenerateImage(idScript, fileName){
   return Ajax.post(`${baseURL}/integration/regenerateImage/${idScript}/${fileName}`, {});
}

export function postVisitorInfo(idScript, email, phoneNumber, other){
   return Ajax.post(baseURL + '/integration/postVisitorInfo', { idScript, email, phoneNumber, other});
}

export function uploadVideo(idScript, video, sendOptions){
   return Ajax.upload(baseURL + '/integration/uploadVideo', video, {idScript, sendOptions});
}

export function updateLastUsed(){
   console.debug('updateLastUsed');
}

export function submitSessionData(data){
   let route = window.location.pathname.split('/').pop();
   data.fromWeb = true;
   let fullData = {
      data : data,
      boothName : '',
      idKiosk: '',
      idScript : window.BV.idScript,
      route,
      idSession : window.BV.idSession
   };
   return Ajax.post(baseURL + '/integration/sendSessionLogsFromWeb', {fullData} );
}

export function uploadImageWithFilename(imageData, fileName, idScript) {
   return Ajax.upload(baseURL + '/integration/uploadImageWithFilename', imageData, { fileName, idScript });
}

export function uploadVideoWithFilename(videoData, fileName, idScript) {
   return Ajax.upload(baseURL + '/integration/uploadVideoWithFilename', videoData, { fileName, idScript });
}