export class ProgressRing{
   constructor(width){
      let strokeWidth = 4;
      let radius = Math.floor((width - strokeWidth)/2);
      let circumference = Math.round(2 * Math.PI * radius);

      let progressRing = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      progressRing.classList.add('progress-ring');
      progressRing.setAttribute('width', width.toString());
      progressRing.setAttribute('height', width.toString());
      progressRing.setAttributeNS('http://www.w3.org/2000/xmlns/', 'xmlns:xlink', 'http://www.w3.org/1999/xlink');


      let progressRingCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
      progressRingCircle.classList.add('progress-ring-circle');
      progressRingCircle.setAttribute('stroke', 'white');
      progressRingCircle.setAttribute('fill', 'transparent');
      progressRingCircle.setAttribute('stroke-width', strokeWidth.toString());
      progressRingCircle.setAttribute('r', radius.toString());
      progressRingCircle.setAttribute('cx', '50%');
      progressRingCircle.setAttribute('cy', '50%');

      progressRingCircle.style.strokeDasharray = `${circumference} ${circumference}`;
      progressRingCircle.style.strokeDashoffset = circumference.toString();

      progressRing.append(progressRingCircle);

      this.element = progressRing;

      this.circle = {
         element: progressRingCircle,
         circumference
      };

      return this;
   }

   setPercentage(percentage){
      let {element, circumference} = this.circle;
      const offset = circumference - percentage / 100 * circumference;
      element.style.strokeDashoffset = offset.toString();
   }
}
