import {IImageProcessorStage} from './IImageProcessorStage.js';
import * as bodySegmentation from '@tensorflow-models/body-segmentation';

export class BodySegmentation extends  IImageProcessorStage{
   constructor() {
      super();
   }

   async init(){
      if(!window.BV){
         window.BV = {};
      }
      if(window.BV.bodySegmentation){
         console.warn('BodySegmentation processing stage can only be used once');
         return;
      }

      const model = bodySegmentation.SupportedModels.BodyPix;
      const segmenterConfig = {
         architecture: 'ResNet50',
         outputStride: 16,
         quantBytes: 4
      };

      this.bodySegmentation = window.BV.bodySegmentation = await bodySegmentation.createSegmenter(model, segmenterConfig);
   }

   onDrawingOptionsChanged() {
      let existingCanvasElement = document.getElementById('bodySegmentationCanvas');
      if(existingCanvasElement){
         existingCanvasElement.remove();
      }
      this.bodySegmentationCanvasElement = document.createElement('canvas');
      this.bodySegmentationCanvasElement.width = this.drawingOptions.dWidth;
      this.bodySegmentationCanvasElement.height = this.drawingOptions.dHeight;
      this.bodySegmentationCanvasElement.setAttribute('id', 'bodySegmentationCanvas');
      document.body.append(this.bodySegmentationCanvasElement);

      this.canvasCtx = this.bodySegmentationCanvasElement.getContext('2d');
      super.onDrawingOptionsChanged();
   }

   onResults(results, originalImage){

      if(results.length === 0){
         this.segmentationInProcess = false;
         return;
      }
      const foregroundColor = {r: 0, g: 0, b: 0, a: 255};
      const backgroundColor = {r: 0, g: 0, b: 0, a: 0};
      const drawContour = false;
      const foregroundThreshold = 0.6;
      bodySegmentation.toBinaryMask(
         results, foregroundColor, backgroundColor, drawContour, foregroundThreshold
      ).then(async binaryMask => {
         let {dx, dy, dWidth, dHeight, imageProcessorMaskBlurAmount} = this.drawingOptions;
         for(let canvas of this.canvasElements) {
            let ctx = canvas.getContext('2d');
            let canvasCtx = ctx;

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, dWidth, dHeight);

            let imageBitmap = await createImageBitmap(binaryMask);

            if(canvas.isForeground) {
               ctx.filter = `blur(${imageProcessorMaskBlurAmount}px)`;
            }

            ctx.drawImage(
               imageBitmap,
               dx, dy, dWidth, dHeight,
               dx, dy, dWidth, dHeight
            );

            if(canvas.isForeground) {
               canvasCtx.globalCompositeOperation = 'source-in';
            }
            else {
               canvasCtx.globalCompositeOperation = 'source-over';
            }

            ctx.filter = 'none';

            canvasCtx.drawImage(
               originalImage,
               dx, dy, dWidth, dHeight,
               dx, dy, dWidth, dHeight
            );

            canvasCtx.restore();

         }
         this.segmentationInProcess = false;
      });
   }

   async run(image, sourceCanvas, qualityMode){
      if(this.segmentationInProcess){
         return;
      }

      let {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.drawingOptions;

      if(dWidth) {
         this.segmentationInProcess = true;
         this.canvasCtx.drawImage(
            image,
            dx, dy, dWidth, dHeight,
            dx, dy, dWidth, dHeight
         );

         let segmentationConfig = {
            multiSegmentation: true,
            segmentBodyParts: false,
            internalResolution: 'low',
            maxDetections: 6,
            refineSteps: 5
         };
         if(qualityMode === 'high'){
            segmentationConfig = {
               multiSegmentation: true,
               segmentBodyParts: false,
               internalResolution: 'full',
               maxDetections: 6,
               refineSteps: 20
            };
         }
         const people = await this.bodySegmentation.segmentPeople(this.bodySegmentationCanvasElement, segmentationConfig);
         this.onResults(people, this.bodySegmentationCanvasElement);
      }
   }


}