import {IImageProcessorStage} from './IImageProcessorStage.js';
import {Pose} from '@mediapipe/pose';

export class PosenetSegmentation extends  IImageProcessorStage{
   constructor() {
      super();
   }

   async init(){
      if(!window.BV){
         window.BV = {};
      }
      if(window.BV.posenet){
         console.warn('Posenet processing stage can only be used once');
         return;
      }
      this.posenet = window.BV.posenet = new Pose({locateFile: (file) => {
         return `https://cdn.jsdelivr.net/npm/@mediapipe/pose/${file}`;
      }});
      this.posenet.setOptions({
         modelComplexity: 1,
         smoothLandmarks: true,
         enableSegmentation: true,
         smoothSegmentation: true,
         minDetectionConfidence: 0.5,
         minTrackingConfidence: 0.5
      });

      this.posenet.onResults(results => {
         //when warming up the model we don't actually need to draw anything;
         if(!this.drawingOptions || !results.segmentationMask){
            return;
         }

         let {sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight} = this.drawingOptions;
         for(let canvas of this.canvasElements) {
            let ctx = canvas.getContext('2d');
            let canvasCtx = ctx;

            canvasCtx.save();
            canvasCtx.clearRect(0, 0, dWidth, dHeight);


            ctx.drawImage(
               results.segmentationMask,
               sx, sy, sWidth, sHeight,
               dx, dy, dWidth, dHeight
            );


            if(canvas.isForeground) {
               canvasCtx.globalCompositeOperation = 'source-in';
            }
            else {
               canvasCtx.globalCompositeOperation = 'source-out';
            }
            //canvasCtx.fillStyle = '#00FF00';
            //canvasCtx.fillRect(0, 0, dWidth, dHeight);

            canvasCtx.drawImage(
               results.image,
               sx, sy, sWidth, sHeight,
               dx, dy, dWidth, dHeight
            );

            canvasCtx.restore();

         }
      });

      let tmp = document.createElement('canvas');
      tmp.width = 50;
      tmp.height = 50;

      await this.posenet.send({image: tmp});
   }

   async run(image){
      await window.BV.posenet.send({image});
   }


}