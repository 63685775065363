import React, { useState } from 'react';
import {InactiveCountdown} from './inactiveCountdown';
import 'regenerator-runtime/runtime';
import {Screen} from './screen';
import {PhotoBoothExperience} from '../photoboothExperience/photoBoothExperience';
import {PhotoboothSource} from '../../shared/photoboothScript.js';
import {ScreenType} from '../../shared/dataTypes.js';
import {getOS, isIOS} from '../systeminfo.js';
import {
   Dialog
} from '@mui/material';
import {v4} from 'uuid';
//must be of type PhotoBoothScript (see dataTypes.js)
//import {photoboothScript} from '../photoboothScripts/spk';

class App extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         screen: 'preliminary',
         videoSrc: '',
         cameraDirection: 0,
         userVariables: {},
         userSelectionsByScreen: {},
         image: null,
         inactive: false,
         initTime: new Date(),
         contentWidth: 0,
         contentHeight: 0,
         curScreenIndex: 0,
         photoboothInitialized: false,
         debugMode: false,
         adminModalOpen: false,
         sessionStartTime: Date.now()
      };
      this.flipImage = true;
      this.inactiveTimeout = null;
      this.resizeEventListener = null;
      this.closeGestureData = {
         isMouseDown: false,
         touchedPoints: []
      };
      this.updateLastUsedInterval = null;
   }

   reset(method){
      try {
         if(!this.doNotSendSessionData && (!BVExtras.isWeb || method === 'next')) {
            BVExtras?.submitSessionData?.({
               userVariables: this.state.userVariables,
               startTime: this.state.sessionStartTime,
               endTime: Date.now(),
            }).then(() => {
               let idSession = v4();

               if(window.BV)
                  window.BV.idSession = idSession;
               else
                  window.BV = {idSession};
            });


         }
      }
      catch(e){
         //do nothing, not a problem.
      }

      window?.BV?.photoBoothExperience?.clearUserVariables?.();
      this.setState({
         userVariables: {},
         userSelectionsByScreen: {},
         curScreenIndex: 0,
         inactive: false
      });
   }

   toggleDebugMode(){
      this.setState({debugMode: !this.state.debugMode});
      document.dispatchEvent(
         new CustomEvent(
            'debugModeToggle',
            {detail: {debugMode :this.state.debugMode}}
         )
      );
   }

   async componentDidMount() {

      document.addEventListener('keyup', (e)=> {
         if(e.ctrlKey && e.key === 'Shift'){
            this.toggleDebugMode();
         }

         if(e.ctrlKey && e.key === '1'){
            localStorage.setItem('debugVideoSelect', '1');
            alert('playing video 1');
         }
         if(e.ctrlKey && e.key === '2'){
            localStorage.setItem('debugVideoSelect', '2');
            alert('playing video 2');
         }
         if(e.ctrlKey && e.key === '3'){
            localStorage.setItem('debugVideoSelect', '3');
            alert('playing video 3');
         }
         if(e.ctrlKey && e.key === '4'){
            localStorage.setItem('debugVideoSelect', '4');
            alert('playing video 4');
         }
         if(e.ctrlKey && e.key === '0'){
            localStorage.setItem('debugVideoSelect', '0');
            alert('playing random video');
         }
      });

      document.addEventListener('mousedown', (e)=> {
         this.closeGestureData.isMouseDown = true;
         this.closeGestureData.touchedPoints = [];
      });

      document.addEventListener('touchstart', (e)=> {
         this.closeGestureData.isMouseDown = true;
         this.closeGestureData.touchedPoints = [];
      });

      document.addEventListener('mouseup', (e)=> {
         this.closeGestureData.isMouseDown = false;
      });

      document.addEventListener('touchend', (e)=> {
         this.closeGestureData.isMouseDown = false;
      });

      document.addEventListener('touchcancel', (e)=> {
         this.closeGestureData.isMouseDown = false;
      });

      let targetWidth = window.innerWidth / 4.0;
      let targetHeight = window.innerHeight / 4.0;

      let isTopLeft = (e) => {
         return e.clientX < targetWidth && e.clientY < targetHeight;
      };

      let isTopRight = (e) => {
         return e.clientX > window.innerWidth - targetWidth && e.clientY < targetHeight;
      };

      let isBottomRight = (e) => {
         return e.clientX > window.innerWidth - targetWidth && e.clientY > window.innerHeight - targetHeight;
      };

      let isBottomLeft = (e) => {
         return e.clientX < targetWidth && e.clientY > window.innerHeight - targetHeight;
      };

      let onMove = (e) => {
         //close if you swipe back and forth on the bottom 3 times
         if(this.closeGestureData.isMouseDown){
            targetWidth = window.innerWidth / 4.0;
            targetHeight = window.innerHeight / 4.0;
            if(
               this.closeGestureData.touchedPoints.length === 6
               && isBottomLeft(e)
            ){
               console.debug('show admin modal');
               this.setState({adminModalOpen: true});
            }
            else if(
               this.closeGestureData.touchedPoints.length % 2 === 0
               && isBottomLeft(e)
            ){
               this.closeGestureData.touchedPoints.push(1);
               console.debug('close touch points: ' + this.closeGestureData.touchedPoints.length);
            }
            else if(
               this.closeGestureData.touchedPoints.length % 2 === 1
               && isBottomRight(e)
            ){
               this.closeGestureData.touchedPoints.push(1);
               console.debug('close touch points: ' + this.closeGestureData.touchedPoints.length);
            }
         }
      };

      document.addEventListener('mousemove', (e)=> {
         onMove(e);
      });

      document.addEventListener('touchmove', (e)=> {
         let moveData ={};
         if(e.touches.length > 0){
            moveData.clientX = e.touches[0].clientX;
            moveData.clientY = e.touches[0].clientY;
         }

         onMove(moveData);
      });

      this.handleResize = () => {
         if(this.resizeTimeout){
            clearTimeout(this.resizeTimeout);
         }
         this.resizeTimeout = setTimeout(async () => {
            let contentWidth = Math.min(
               this.props.photoboothScript.maxWidth || Number.MAX_SAFE_INTEGER,
               this.props.parentElement.clientWidth
            );
            let contentHeight = this.props.parentElement.clientHeight;

            if(this.props.photoboothScript.appAspectRatio){
               contentWidth = contentHeight * parseFloat(this.props.photoboothScript.appAspectRatio);
            }

            this.setState({
               contentWidth: contentWidth,
               contentHeight: this.props.parentElement.clientHeight,
            });
         }, 300);
      };

      window.addEventListener('resize', this.handleResize);
      this.doNotSendSessionData = localStorage.getItem('doNotSendSessionData');
      if(this.doNotSendSessionData === 'false')
         this.doNotSendSessionData = false;

      let idSession = v4();

      if(window.BV)
         window.BV.idSession = idSession;
      else
         window.BV = {idSession};

      try {
         if( window.BVExtras?.isWeb && !this.doNotSendSessionData)
            BVExtras?.submitSessionData?.({
               userVariables: this.state.userVariables,
               startTime: this.state.sessionStartTime,
               endTime: Date.now(),
            });
      }
      catch (e){
         //do nothing, not a problem.
      }

      let contentWidth = Math.min(
         this.props.photoboothScript.maxWidth ||
         Number.MAX_SAFE_INTEGER, this.props.parentElement.clientWidth
      );

      let contentHeight = this.props.parentElement.clientHeight;

      if(this.props.photoboothScript.appAspectRatio){
         contentWidth = contentHeight * parseFloat(this.props.photoboothScript.appAspectRatio);
      }

      this.setState({
         contentWidth: contentWidth,
         contentHeight: this.props.parentElement.clientHeight,
      });

      if(!window.BV){
         window.BV = {};
      }

      let videoSources = this.props.photoboothScript.sources.map(x => new PhotoboothSource(x));
      let overlayVariables = this.props.photoboothScript.overlayVariables;

      if(!window.BV.photoBoothExperience) {
         window.BV.photoBoothExperience = new PhotoBoothExperience({
            delayOut: this.props.photoboothScript.delayOut,
            width: contentWidth,
            height: contentHeight,
            fixedAspectRatio: this.props.photoboothScript.fixedAspectRatio,
            flipFinalScreenshotX: this.props.photoboothScript.flipFinalScreenshotX,
            finalMediaIsVideo: this.props.photoboothScript.finalMediaIsVideo,
            onCapture: (videoSrc, rawWebcamSrc, autoAdvanceScreen) => this.handleVideoCaptured(videoSrc, rawWebcamSrc, autoAdvanceScreen),
            baseUrl: this.props.photoboothScript.baseUrl,
            videoSources,
            overlayVariables,
            screenshotTimer: this.props.photoboothScript.photoTimer,
            ui: this.props.photoboothScript.captureUI,
            onLoad: this.props.photoboothScript.onLoad,
            customFunctions: this.props.customFunctions,
            webcamFpsLimit:  this.props.photoboothScript.webcamFpsLimit,
            videoCaptureMode: this.props.photoboothScript.videoCaptureMode,
            webcamFacingMode: this.props.photoboothScript.webcamFacingMode,
            captureImageHeight: this.props.photoboothScript.captureImageHeight,
            captureImageWidth: this.props.photoboothScript.captureImageWidth,
            idealCameraHeight: this.props.photoboothScript.idealCameraHeight,
            idealCameraWidth: this.props.photoboothScript.idealCameraWidth,
            idealCameraFPS: this.props.photoboothScript.idealCameraFPS,
            imageProcessorMaskBlurAmount: this.props.photoboothScript.imageProcessorMaskBlurAmount,
            cameraRotation: this.props.photoboothScript.cameraRotation,
            webcamConstraints: this.props.photoboothScript.webcamSettings
         });
         await window.BV.photoBoothExperience.init();
      }

      if(!window.BV.photoBoothExperience.sourcesInitialized){
         await window.BV.photoBoothExperience.initSources();
         this.setState({photoboothInitialized: true});
         this.props?.customFunctions?.onInitializationComplete?.();
      }

      window.BV.app = this;
      if(!window.fathom)
         window.fathom = {};

      if(!window.fathom.siteId){
         window.fathom.siteId = this.props.photoboothScript?.fathomConfig?.siteId;
      }

      let allScripts = document.getElementsByTagName('script');
      let analyticsScript;
      for(let script of allScripts) {
         if(script.src === 'https://cdn.usefathom.com/script.js')
            analyticsScript = script;
      }

      if(!analyticsScript) {
         analyticsScript = document.createElement('script');
         analyticsScript.setAttribute('defer' ,'');
         analyticsScript.setAttribute('src', 'https://cdn.usefathom.com/script.js');
      }
      
      analyticsScript.setAttribute('data-site' ,window.fathom.siteId);
      document.head.append(analyticsScript);

      if(isIOS()) {
         var os = getOS();
         if(os.version.major && os.version.major < 15){
            alert("Please update iOS to version 15 or above.");
         }
      }

      if(!BVExtras.isWeb ){
         let intervalTime = 1000 * 10;
         if(this.updateLastUsedInterval){
            window.clearInterval(this.updateLastUsedInterval);
         }
         this.updateLastUsedInterval = window.setInterval(() => {
            BVExtras.updateLastUsed();
         }, intervalTime);
      }

      this.init();
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
   }

   init() {
      document.addEventListener('click', () => {this.countdown();});
   }

   countdown () {
      let curScreen = this.props.photoboothScript.screens[this.state.curScreenIndex];
      if(this.props.inactivityTimeLimit) {
         if(curScreen.type !== ScreenType.Welcome &&
            curScreen.type !== ScreenType.Capture &&
            parseInt(this.state.curScreenIndex) !== parseInt(this.props.photoboothScript.configScreenIndex) ||
            this.state.inactive
         ) {
            if(this.inactiveTimeout)
               clearTimeout(this.inactiveTimeout);

            this.setState({inactive: false});
            this.inactiveTimeout = setTimeout(() => {
               // after 30 second, if no input, set inactive
               if(!this.state.debugMode) {
                  this.setState({inactive: true});
               }
            }, this.props.inactivityTimeLimit);
         } else {
            if(this.inactiveTimeout)
               clearTimeout(this.inactiveTimeout);
         }
      }
   }

   countdownEnded() {
      this.reset();
   }

   async goToScreen(index, method){
      await this.props.customFunctions?.onScreenChange?.(this.state.curScreenIndex, index, method);

      if(index === undefined){
         return;
      }

      let numScreensVisited = this.state.userVariables.numScreensVisited;
      if(!numScreensVisited){
         numScreensVisited = 0;
      }
      await this.setUserVariable('numScreensVisited', numScreensVisited + 1);

      if(parseInt(index) === 0 ){
         this.reset(method);
      }
      else {
         if(parseInt(this.state.curScreenIndex) === 0){
            this.setState({curScreenIndex: index, sessionStartTime: Date.now()});
         }
         else {
            this.setState({curScreenIndex: index});
         }
      }
   }

   goToPreviousScreen(curScreen){
      if(parseInt(curScreen.backScreenIndex) === 0 ){
         this.reset();
      }
      else {
         this.setState({curScreenIndex: curScreen.backScreenIndex});
      }
   }

   goToConfigScreen(){
      if(this.props.photoboothScript.configScreenIndex) {
         this.goToScreen(this.props.photoboothScript.configScreenIndex, 'jump').then(() => {});
      }
      else{
         alert('No Config Screen Found');
      }
   }

   closeApp() {
      console.log('close app');
      BVExtras?.quitApp();
   }

   render() {
      let curScreen = this.props.photoboothScript.screens[this.state.curScreenIndex];

      let curTime = new Date();
      if(this.state.curScreenIndex === 0){
         let timeSinceRefresh = curTime - this.state.initTime;
         if(timeSinceRefresh > 1000 * 60 * 5){
            //tru to refresh the page, but only if no usage has happened in the past 10 seconds
            window.setTimeout(() => {
               if(this.state.curScreenIndex === 0){
                  window.location.reload();
               }
            }, 5000);
         }
      }
      return (
         <div id={'photoboothAppMainContainer'} className={this.state.debugMode ? 'debug-mode' : ''}>
            <InactiveCountdown
               visible={this.state.inactive}
               onEnded={() => {this.countdownEnded();}}
               baseUrl={this.props.photoboothScript.baseUrl}
               contentHeight={this.state.contentHeight}
               contentWidth={this.state.contentWidth}
               videoUrl={this.props.photoboothScript.countdownVideoUrl || '/Videos/Reset.webm'}
            />
            <div id={'incorrectOrientationDiv'}>
               <div id={'incorrectOrientationDivText'}> </div>
            </div>
            <AdminModal
               show={this.state.adminModalOpen}
               closeFunction={() => this.setState({adminModalOpen: false})}
               goToConfigScreen={() => this.goToConfigScreen()}
               toggleDebugMode={() => this.toggleDebugMode()}
            />
            <Screen
               {...curScreen}
               backgroundVariables={this.props.photoboothScript.backgroundVariables}
               logoVariables={this.props.photoboothScript.logoVariables}
               overlayVariables={this.props.photoboothScript.overlayVariables}
               contentHeight={this.state.contentHeight}
               contentWidth={this.state.contentWidth}
               baseUrl={this.props.photoboothScript.baseUrl}
               resolveUrl={(url) => this.resolveUrl(url)}
               goToNextScreen={async () => await this.goToScreen(curScreen.nextScreenIndex, 'next')}
               goToPreviousScreen={async () => await this.goToScreen(curScreen.backScreenIndex, 'previous')}
               goToScreen={(ind) => this.goToScreen(ind, 'jump')}
               debugMode={this.state.debugMode}
               /*For Capture*/
               flip={this.flipImage}
               /*For Review*/
               allowSocialShare={this.props.allowSocialShare}
               allowDirectDownload={this.props.allowDirectDownload}
               emailProjectName={this.props.emailProjectName}
               smsProjectName={this.props.photoboothScript.smsProjectName}
               smsText={this.props.smsText}
               videoSrc={this.state.videoSrc}
               onButtonClick={(img) => { this.setState({image: img}); }}
               photoboothInitialized={this.state.photoboothInitialized}
               customFunctions={this.props.customFunctions}
               setUserVariable={(key, value) => this.setUserVariable(key, value)}
               setUserVariables={(dict) => this.setUserVariables(dict)}
               userVariables={this.state.userVariables}
               userSelectionsByScreen={this.state.userSelectionsByScreen}
               setUserSelection={(screen, objects) => this.setUserSelection(screen, objects)}
               formstackConfig={this.props.photoboothScript.formstackConfig}
               fathomConfig={this.props.photoboothScript.fathomConfig}
               closeApp={()=> this.closeApp()}
               sessionStartTime={this.state.sessionStartTime}
            />

         </div>
      );
   }

   handleCameraDirectionSelected(direction){
      console.log('handleCameraDirectionSelected: ' + direction);
      this.setState({ cameraDirection: direction});
   }

   async setUserSelection(screen, objects){
      try {
         let userSelectionsByScreen = {...this.state.userSelectionsByScreen};
         userSelectionsByScreen[screen] = objects;
         await new Promise(resolve => {
            this.setState({userSelectionsByScreen}, () => {
               resolve();
            });
         });
      }
      catch (e){
         console.warn(e);
      }
   }

   async setUserVariable(key, value){
      try {
         let userVariables = {...this.state.userVariables};
         userVariables[key] = value;
         await window.BV.photoBoothExperience.updateUserVariable(key, value);
         await new Promise(resolve => {
            this.setState({userVariables}, () => {
               resolve();
            });
         });
      }
      catch (e){
         console.warn(e);
      }
   }

   async setUserVariables(dict){
      try {
         let userVariables = {...this.state.userVariables};
         for(let key of Object.keys(dict)){
            userVariables[key] = dict[key];
         }
         await window.BV.photoBoothExperience.updateUserVariables(dict);
         await new Promise(resolve => {
            this.setState({userVariables}, () => {
               resolve();
            });
         });
      }
      catch (e){
         console.warn(e);
      }
   }

   async handleVideoCaptured(src, rawWebcamSrc, autoAdvanceScreen = true){
      console.debug('handleVideoCaptured: ' + src);
      setTimeout(()=>{
         this.countdown();
      }, 100);

      let newState = {
         videoSrc: src,
         rawWebcamSrc
      };

      await this.setUserVariables({rawWebcamSrc});

      if(autoAdvanceScreen){
         newState['curScreenIndex'] = this.props.photoboothScript.screens[this.state.curScreenIndex].nextScreenIndex;
      }

      this.setState(newState);
   }
}

function AdminModal (props) {
   const { show, closeFunction} = props;

   const [showMessage, setShowMessage] = useState(false)

   const shutdown = () => {
      closeFunction();
      setShowMessage(true);
      BVExtras?.computerShutdown()?.();
   };

   return (
      <React.Fragment>
         <Dialog open={show} >
            <div id="admin-modal">
               <div id="appInfoLayer">
                  <div id="appName">
                     <img id="appCameraIcon" src="../camera_icon.png"/>
                     BV Photobooth
                  </div>
                  <div id="appVersion">{props.appVersion}</div>
                  <img id="appExitIcon" src="../Close_icon.png"
                     onClick={() =>  closeFunction()}
                  />
               </div>
               <div id="mainPage">
                  <div id="mainPageFrame">
                     <img id="mainPageFrameImage" src="../fan-fotos-logo.png" />
                     <div id="headText">Click below to quit, shutdown or configure</div>
                  </div>
                  <div id="menu">
                     <div
                        onClick={() => BVExtras?.quitApp?.()}
                        class="button"
                     >
                        Quit App
                     </div>
                     <div
                        onClick={() => shutdown()}
                        class="button"
                     >
                        Shutdown
                     </div>
                     <div
                        onClick={() => {
                           props.goToConfigScreen();
                           closeFunction();
                        }}
                        class="button"
                     >
                        Configure
                     </div>
                     <div
                        onClick={() => {
                           props.toggleDebugMode();
                           closeFunction();
                        }}
                        className="button"
                     >
                        Debug Mode
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
         <Dialog open={showMessage} >
            <div id="message-modal">
               <div id="appInfoLayer">
                  <div id="appName">
                     <img id="appCameraIcon" src="../camera_icon.png"/>
                     BV Photobooth
                  </div>
               </div>
               <div id="mainPage">
                  <div id="messagePageFrame">
                     <img id="mainPageFrameImage" src="../fan-fotos-logo.png" />
                     <div id="headText">The kiosk is shutting down,<br/>this may take a minute.</div>
                  </div>
               </div>
            </div>
         </Dialog>
      </React.Fragment>

      
   );
}

export default App;
