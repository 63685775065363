const baseURL = (typeof (window) !== 'undefined' ? window.location.origin : null);
import * as Ajax from './ajax.js';

export function getScriptsForOrg(){
   return Ajax.get(`${baseURL}/organization/scripts` );
}

export function getScriptsByUser(idUser) {
   return Ajax.get(`${baseURL}/organization/scripts/`+ idUser);
}

export function createScriptForUser (nickname, json, sharedFiles, isKiosk, isWeb, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData) {
   return Ajax.post(`${baseURL}/organization/scripts/create`, {nickname, json, sharedFiles, isKiosk, isWeb, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData});
}
export function createScriptByUser (idUser, nickname, json, sharedFiles, isKiosk, isWeb, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData) {
   return Ajax.post(`${baseURL}/organization/scripts/create/${idUser}`, {nickname, json, sharedFiles, isKiosk, isWeb, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData});
}

export function renameScript (idScript, nickname) {
   return Ajax.post(`${baseURL}/organization/scripts/rename`, {idScript, nickname});
}

export function getKioskInfoListForUser() {
   return Ajax.get(baseURL + '/organization/kioskInfoList');
}
export function getKioskInfoListByUser(idUser) {
   return Ajax.get(baseURL + '/organization/kioskInfoList/' + idUser);
}
export function updateKioskScript(idKiosk, idScript){
   return Ajax.post(baseURL + '/organization/kioskInfo/update', {idKiosk, idScript});
}

export function getDataForUser() {
   return Ajax.get(baseURL + '/user/data');
}

export function getUserData(idUser) {
   return Ajax.get(baseURL + '/user/data/'+ idUser);
}

export function updateUserData(idUser, userData) {
   return Ajax.post(baseURL + '/user/data/'+ idUser, {userData});
}

export function updateUserInfo(idUser, email, firstName, lastName){
   return Ajax.post(baseURL + '/user/info/'+ idUser, {email, firstName, lastName});
}

export function getUsers() {
   return Ajax.get(baseURL + '/editor/users');
}

export function updateUsersRoles(users) {
   return Ajax.post(baseURL + '/editor/users/role', {users});
}

export function updateUserScripts(users){
   return Ajax.post(baseURL + '/editor/users/scripts', {users});
}

export function updateUserKiosks(users){
   return Ajax.post(baseURL + '/editor/users/kiosks', {users});
}

export function getScripts(){
   return Ajax.get(baseURL + '/editor/script');
}

export function getScript(idScript) {
   return Ajax.get(baseURL + '/editor/script/' + idScript);
}

export function getRoutes(){
   return Ajax.get(baseURL + '/editor/route/');
}

export function getKioskInfoList(){
   return Ajax.get(baseURL + '/editor/kioskInfoList');
}

export function createRoute(string, idPhotoboothScript){
   return Ajax.post(baseURL + '/editor/route/', {string, idPhotoboothScript});
}

export function updateRoute(idRoute, string, idPhotoboothScript){
   return Ajax.post(baseURL + '/editor/route/' + idRoute, {string, idPhotoboothScript});
}

export function deleteRoute(idRoute) {
   return Ajax.post(baseURL + '/editor/deleteRoute/' + idRoute);
}

export function uploadScript(script, idScript, isWeb, isKiosk, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData){
   return Ajax.post(baseURL + '/editor/script/' + idScript, {script, isWeb, isKiosk, isLive, idEmailTemplate, emailSubject, idReminderTemplate, reminderEmailSubject, editorData});
}

export function uploadSelectionsMap (idScript, selectionsMap) {
   return Ajax.post(baseURL + '/editor/selectionsMap/' + idScript, { selectionsMap });
}

export function uploadContactInfoScript(script, idScript){
   return Ajax.post(baseURL + '/editor/contactInfoScript/' + idScript, {script});
}

export function uploadGetMediaScript(script, idScript) {
   return Ajax.post(baseURL + '/editor/getMediaScript/' + idScript, {script});
}

export function updateKioskInfo(idKiosk, settings, nickname, isLive, macAddress){
   return Ajax.post(baseURL + '/kiosk/kioskInfo/' + idKiosk, {settings, nickname, isLive, macAddress});
}

export function updateKioskUser(idKiosk, idUser) {
   return Ajax.post(baseURL + '/kiosk/kioskUser/' + idKiosk, {idUser});
}

export function deleteScript(idScript){
   return Ajax.post(baseURL + '/editor/script/delete/' + idScript);
}

export function getFiles(idScript){
   return Ajax.get(`${baseURL}/editor/${idScript}/files`);
}
// --- get deliveries ---
export function getDeliveriesWithIdKiosk(idKiosk, startTime, endTime, idUser, tz, onlyShowLive) {
   return Ajax.get(`${baseURL}/editor/delivery/kiosk/${idKiosk}?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}`);
}
export function getMultipleDeliveriesByKiosks(startTime, endTime, idUser, tz , onlyShowLive, kiosks){
   return Ajax.get(`${baseURL}/editor/delivery/multiple/kiosk?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}&kiosks=${kiosks}`);
}
export function getDeliveriesWithIdScript(idScript, startTime, endTime, tz , onlyShowLive) {
   return Ajax.get(`${baseURL}/editor/delivery/script/${idScript}?startTime=${startTime}&endTime=${endTime}&tz=${tz}&onlyShowLive=${onlyShowLive}`);
}
export function getMultipleDeliveriesByScripts(startTime, endTime, idUser, tz , onlyShowLive, themes) {
   return Ajax.get(`${baseURL}/editor/delivery/multiple/script?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}&themes=${themes}`);
}

// --- get sessions ---
export function getSessionsWithIdKiosk(idKiosk, startTime, endTime, idUser, tz , onlyShowLive) {
   return Ajax.get(`${baseURL}/editor/sessionInfo/kiosk/${idKiosk}?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}`);
}
export function getMultipleSessionsByKiosks(startTime, endTime, idUser, tz , onlyShowLive, kiosks) {
   return Ajax.get(`${baseURL}/editor/sessionInfo/multiple/kiosk?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}&kiosks=${kiosks}`);
}
export function getSessionsWithIdScript(idScript, startTime, endTime, tz , onlyShowLive) {
   return Ajax.get(`${baseURL}/editor/sessionInfo/script/${idScript}?startTime=${startTime}&endTime=${endTime}&tz=${tz}&onlyShowLive=${onlyShowLive}`);
}
export function getMultipleSessionsByScripts(startTime, endTime, idUser, tz , onlyShowLive, themes) {
   return Ajax.get(`${baseURL}/editor/sessionInfo/multiple/script?startTime=${startTime}&endTime=${endTime}&idUser=${idUser}&tz=${tz}&onlyShowLive=${onlyShowLive}&themes=${themes}`);
}

export function getScriptBackups(idScript) {
   return Ajax.get(`${baseURL}/editor/scriptBackups/${idScript}`);
}

export function getScriptBackupById(idScript, idScriptBackup) {
   return Ajax.get(`${baseURL}/editor/scriptBackups/${idScript}/${idScriptBackup}`);
}

export function deleteScriptBackup(idScriptBackup) {
   return Ajax.post(`${baseURL}/editor/deleteScriptBackup/${idScriptBackup}`);
}

export function uploadConfigFile(idUser, file, projectFolder, configEditorVariable, userData) {
   return Ajax.upload(baseURL + '/file/uploadConfigFile', file, {idUser, projectFolder, configEditorVariable, userData});
}

export function uploadFile(file, bucket, folder, fileName, sanitizeFileNName) {
   return Ajax.upload(baseURL + '/file/uploadFile', file, {bucket, folder, fileName, sanitizeFileNName});
}

export function deleteFile(idUser, projectFolder, deleteCandidate, userData) {
   return Ajax.post(baseURL + '/file/delete', {idUser, projectFolder, deleteCandidate, userData} );
}

export function createsFolderS3(bucket, folderName){
   return Ajax.upload(baseURL + '/file/createFolder', {}, {bucket, folderName});
}

export function getFormstackForms(apiToken){
   return Ajax.get(`${baseURL}/integration/getFormstackForms?apiToken=${apiToken}`);
}

export function getFormstackFormInfo(apiToken, idForm){
   return Ajax.get(`${baseURL}/integration/getFormstackFormInfo?apiToken=${apiToken}&idForm=${idForm}`);
}

export function getFormstackFormSubmissions(apiToken, idForm){
   return Ajax.get(`${baseURL}/integration/getFormstackFormSubmissions?apiToken=${apiToken}&idForm=${idForm}`);
}

export function getLatestMediaDeliveriesForKiosk(idKiosk){
   return Ajax.get(`${baseURL}/editor/getLatestMediaDeliveriesForKiosk/${idKiosk}`);
}

export function replaceImage(image)
{
   return Ajax.post(`${baseURL}/admin/replaceImage`, {image});
}

export function regenerateImage(image)
{
   return Ajax.post(`${baseURL}/admin/regenerateImage`, {image});
}
export function archiveRawImage(idScript, fileName)
{
   return Ajax.post(`${baseURL}/admin/archiveRawImage`, {idScript, fileName});
}
export function archiveProcessedImages(idScript, fileName)
{
   return Ajax.post(`${baseURL}/admin/archiveProcessedImages`, {idScript, fileName})
}
export function deleteRawImage(image)
{
   return Ajax.post(`${baseURL}/admin/deleteRawImage`, {image})
}
export function deleteProcessedImages(idScript, fileName)
{
   return Ajax.post(`${baseURL}/admin/deleteProcessedImages`, {idScript, fileName})
}