import React from 'react'
import {CustomButtonList} from "./customButtonList";

export function ErrorScreen (props){
   return (
      <div id={'errorScreen'}>
         <div>
            An error occurred. Please try again later. If the problem persists, please try re-starting your phone.
         </div>
         <div>
            <CustomButtonList customButtons={props.customButtons} />
         </div>
      </div>
   );
}

